import React, { useState, useEffect, useRef } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Select from "react-select";
import SearchIcon from "../../assets/icons/search-icon.svg";
import SubmitBtn from "./submitBtn";
import Datepicker from "./datepicker";
import { getHyphenYYYYMMDDHHMMSS, getDDMMMYYYY, getHyphenDDMMYYYY } from '../common/utils';
import moment from "moment";
import BulkForceFileHandler from "../layouts/changebulkuploadhandler";
import Updatetrucksdetails from "./updateTrucksDetailsComponent";
import UpdateContainerdetails from "./updateContainerdetails";
import ClosedComponentForTrans from "./closedfortransporter";
import TransallComponent from "./transAllComponent";
import redirectURL from "../redirectURL";
import Modal from 'react-responsive-modal';

const TransporterComponent = (props) => {
    var gridRef = useRef();
    var [check, setcheck] = useState(1)
    var [checkinput, setcheckinput] = useState(0)
    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    const [isClicked, setIsClicked] = useState({
        all: true,
        containerDetails: false,
        truckDetails: true,
        closed: false
    });
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [woReleaseDate, setWoReleaseDate] = useState({
        wo_release_from_date: '',
        wo_release_to_date: ''
    })
    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    var [loadshow, setloadshow] = useState('show-n')
    var [overly, setoverly] = useState('show-n')
    var [sliderForBulkupload, setsliderForBulkupload] = useState('')
    var [open, setopen]= useState(false) 
    var [file, setfile] = useState('')
    var [csvcontent, setcsvcontent] = useState("")
    var [rowData1, setrowData1] = useState([])
    var [showGrid, setshowGrid] = useState(false)
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var typeofselection = [
        { label: 'Ship To Country', value: 'ship_to_country' },
        { label: 'POD', value: 'pod' }, { label: 'CHA', value: 'cha' },
        { label: 'Plant', value: 'plant' },
        { label: 'WO Release Date', value: 'wo_release_date' },
        { label: 'Production Date', value: 'production_date' },
        { label: 'Latest Shipment Date', value: 'latest_shipment_date' },
        { label: 'Tentative Vessel ETD', value: 'tentative_vessel_etd' },
        // { label: 'Tentative Vessel ETD', value: 'tentative_vessel_etd' },
    ]
    var [validbulkfile,setvalidbulkfile] = useState(false)
    var [errormsg,seterrormsg] = useState('')
    const [showAddForm, setshowAddForm] = useState('')
    const [manualUploadDetails, setmanualUploadDetails] = useState({
        wrno: '',
        remarks_by_ib: '',
        stuffing_date: ''
    })
    var [workordernoList, setworkOrderList] = useState([])
    var [counters, setcount] = useState({
        truck_details_count: [],
        container_details_count: []
    })
    var [tabForLeo, setTabForLeo] = useState({
        leo_new: [],
        leo_planned: [],
        leo_modified: [],
        container_new_1: [],
        container_new_2: []
    })
    var [transcount, settranscount] = useState({
        transporterdetails: [],
        containerdetails: [],
        closed_data: []
    })
    var [tabsfortrucks, settabsfortrucks] = useState({
        tpt_trucks_planned: [],
        tpt_trucks_new: [],
        tpt_trucks_modified: []
    })
    var [tabsforcontainers, settabsforcontainers] = useState({
        tpt_containers_planned: [],
        tpt_containers_new: [],
        tpt_containers_modified: [],
        container_new_1: [],
        container_new_2: [],
        container_new_3: []
    })
    const [reloadData, setReloadData] = useState(true);
    var columnsforbulk= [
        {
            headerName: 'Work Order No',
            field: 'work_order_no',
            resizable: true,
            width: 180,
            filter: true,
        },
        {
            headerName: 'Truck No',
            field: 'truck_no',
            resizable: true,
            width: 180,
            filter: true,
        },
        {
            headerName: 'Container No',
            field: 'container_no',
            resizable: true,
            width: 180,
            filter: true,
            editable:true,
            valueGetter: (params) => {
                const containerNo = params.data.container_no;
                // Check if container_no is in the correct format
                const containerPattern = /^[A-Za-z]{4}\d{7}$/;
                //console.log(containerPattern.test(containerNo));
                if (containerNo && containerPattern.test(containerNo)) {
                    return containerNo;
                } else {
                    return "Invalid Container No";
                }
            },
            cellStyle: (params) => {
                const date = params.value;
                // Apply red color if the date format is invalid
                if (date === "Invalid Container No") {
                    return { background: 'red', color: 'black' };
                }
                return null;
            }
        },
        {
            headerName: "Pickup Date",
            field: "pick_up_date",
            width: 190,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: (params) => {
                const date = params.data.pick_up_date;
                // Check if date is in DD-MM-YYYY HH:mm format
                const datePattern = /^\d{2}-\d{2}-\d{4}\s+\d{2}:\d{2}$/;
                //console.log(datePattern.test(date));
                if (date && datePattern.test(date)) {
                    return date;
                } else {
                    return "Invalid Date Format";
                }
            },
            cellStyle: (params) => {
                const date = params.value;
                // Apply red color if the date format is invalid
                if (date === "Invalid Date Format") {
                    return { background: 'red', color: 'black' };
                }
                return null;
            }
        },
        
        
        // {
        //     headerName: 'Shipping Seal',
        //     field: "shipping_seal",
        //     resizable: true,
        //     width: 180,
        //     filter: true,
        // },
        // {
        //     headerName: 'Container Tare Weight(kgs)',
        //     field: 'container_tare_weight',
        //     resizable: true,
        //     width: 190,
        //     filter: true,
        // },
        // {
        //     headerName: 'Container Gross Weight(kgs)',
        //     field: 'container_gross_weight',
        //     resizable: true,
        //     width: 200,
        //     filter: true,
            
        // },
        // {
        //     headerName: 'LR No',
        //     field: 'lr_no',
        //     resizable: true,
        //     width: 200,
        //     filter: true,    
            
        // },
        // {
        //     headerName: "LR Date",
        //     field: "lr_date",
        //     width: 190,
        //     filter: true,
        //     resizable: true,
        //     editable:true,
        //     valueGetter: (params) => {
        //         const date = params.data.lr_date;
        //         // Check if date is in DD-MM-YYYY HH:mm format
        //         const datePattern = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
        //         if (date && datePattern.test(date)) {
        //             return date;
        //         } else {
        //             return "Invalid Date Format";
        //         }
        //     },
        //     cellStyle: (params) => {
        //         const date = params.value;
        //         // Apply red color if the date format is invalid
        //         if (date === "Invalid Date Format") {
        //             return { background: 'red', color: 'black' };
        //         }
        //         return null;
        //     }
        // },
        
        // {
        //     headerName: 'Driver Mobile No',
        //     field: 'driver_mobile_no',
        //     resizable: true,
        //     width: 160,
        //     filter: true,
        // }
        
    ]
    useEffect(() => {
        gettransportercount()
        // getcloseddata()
    }, [props.data])
    const getcloseddata = () => {
        redirectURL.post('/consignments/getdatafortranscompleted').then((resp3) => {
            var leo_new = resp3.data.work_order_level_data1;
            leo_new = leo_new.filter(e => e.pendency_reporting_at_parking <= 0)
            //console.log(leo_new, '115115')
            if (localStorage.getItem('role') == 'tpt') {
                if (localStorage.getItem('plant_code') != '' && localStorage.getItem('plant_code') != undefined && localStorage.getItem('plant_code') != 'undefined') {
                    leo_new = leo_new.filter(e => e.transporter_code == localStorage.getItem('plant_code'))
                }
            }
            settranscount(prev => ({
                ...prev,
                closed_data: leo_new
            }));
            setTabForLeo(prev=>({
                ...prev,
                leo_new:leo_new
            }))
            var container_new_1 = resp3.data.containerDetails1;
            setTabForLeo(prev=>({
                ...prev,
                container_new_1:container_new_1
            }))
            //console.log(container_new_1,'container_new_1container_new_1')
            var container_new_2 = resp3.data.containerDetails2;
            var leo_planned = resp3.data.work_order_level_data2;
            if (localStorage.getItem('role') == 'tpt') {
                if (localStorage.getItem('plant_code') != '' && localStorage.getItem('plant_code') != undefined && localStorage.getItem('plant_code') != 'undefined') {
                    leo_planned = leo_planned.filter(e => e.transporter_code == localStorage.getItem('plant_code'))
                }
            }
            var leo_modified = resp3.data.work_order_level_data3;
            if (localStorage.getItem('role') == 'tpt') {
                if (localStorage.getItem('plant_code') != '' && localStorage.getItem('plant_code') != undefined && localStorage.getItem('plant_code') != 'undefined') {
                    leo_modified = leo_modified.filter(e => e.transporter_code == localStorage.getItem('plant_code'))
                }
            }
            setTabForLeo(prev=>({
                ...prev,
                leo_planned: leo_planned,
                leo_modified: leo_modified,
                // container_new_1: container_new_1,
                container_new_2: container_new_2
            }))
        })
    }
    const gettransportercount = async () => {
        setloadshow("show-m")
        var transporter = []
        try {
            // const resp1 = await redirectURL.post('/consignments/getAllConsignmentsData');
            
            const resp1 = await redirectURL.post('/consignments/getCompleteTransporterData');
            let {allContainerData , allTptData , completedData} = resp1.data
            var transporterDetails = allTptData.work_order_level_data;
            //console.log(transporterDetails, 'transporterDetailstransporterDetails')
            if (localStorage.getItem('role') == 'tpt') {
                if (localStorage.getItem('plant_code') != '' && localStorage.getItem('plant_code') != undefined && localStorage.getItem('plant_code') != 'undefined') {
                    //console.log(localStorage.getItem('plant_code'), 'localStorage.getItem')
                    transporterDetails = transporterDetails.filter(e => e.transporter_code == localStorage.getItem('plant_code'))
                }
            }

            transporterDetails = transporterDetails
            transporter.push(...transporterDetails)
            var tpt_trucks_planned = transporterDetails.filter(e => e.tpt_trucks_planned == 1)
            var tpt_trucks_new = transporterDetails.filter(e => e.tpt_trucks_new == 1 && e.booking_completed !=1)
            var tpt_trucks_modified = transporterDetails.filter(e => e.tpt_trucks_modified == 1)

            settabsfortrucks({
                tpt_trucks_planned: tpt_trucks_planned,
                tpt_trucks_new: tpt_trucks_new,
                tpt_trucks_modified: tpt_trucks_modified
            })
            settranscount(prev => ({
                ...prev,
                transporterdetails: transporterDetails
            }));
            setcount(prev => ({
                ...prev,
                truck_details_count: transporterDetails
            }))
            // const resp2 = await redirectURL.post('/consignments/getDataForContainersDetails');
            var container_new = allContainerData.work_order_level_data1;
            if (localStorage.getItem('role') == 'tpt') {
                if (localStorage.getItem('plant_code') != '' && localStorage.getItem('plant_code') != undefined) {
                    container_new = container_new.filter(e => e.transporter_code == localStorage.getItem('plant_code'))
                }
            }
            var container_new_1 = allContainerData.containerDetails1;
            var container_new_2 = allContainerData.containerDetails2;
            var container_new_3 = allContainerData.containerDetails3;

            var container_planned = allContainerData.work_order_level_data2;
            if (localStorage.getItem('role') == 'tpt') {
                if (localStorage.getItem('plant_code') != '' && localStorage.getItem('plant_code') != undefined) {
                    container_planned = container_planned.filter(e => e.transporter_code == localStorage.getItem('plant_code'))
                }
            }
            var container_modified = allContainerData.work_order_level_data3;
            if (localStorage.getItem('role') == 'tpt') {
                if (localStorage.getItem('plant_code') != '' && localStorage.getItem('plant_code') != undefined) {
                    container_modified = container_modified.filter(e => e.transporter_code == localStorage.getItem('plant_code'))
                }
            }
            settabsforcontainers({
                tpt_containers_new: container_new,
                tpt_containers_planned: container_planned,
                tpt_containers_modified: container_modified,
                container_new_1: container_new_1,
                container_new_2: container_new_2,
                container_new_3: container_new_3
            })
            const containerDetails = [...container_new, ...container_planned, ...container_modified]
            transporter.push(...containerDetails)
            settranscount(prev => ({
                ...prev,
                containerdetails: containerDetails,
            }));
            setcount(prev => ({
                ...prev,
                truck_details_count: containerDetails
            }))

            // redirectURL.post('/consignments/getdatafortranscompleted').then((resp3) => {
            var leo_new = completedData.work_order_level_data1;
            console.log(leo_new,'leo new')
            leo_new = leo_new.filter(e => e.pendency_reporting_at_parking <= 0)
            if (localStorage.getItem('role') == 'tpt') {
                if (localStorage.getItem('plant_code') != '' && localStorage.getItem('plant_code') != undefined && localStorage.getItem('plant_code') != 'undefined') {
                    leo_new = leo_new.filter(e => e.transporter_code == localStorage.getItem('plant_code'))
                }
            }
            console.log(leo_new,'leo new')
            settranscount(prev => ({
                ...prev,
                closed_data: leo_new
            }));
            setTabForLeo(prev => ({
                ...prev,
                leo_new: leo_new
            }))
            var container_new_1 = completedData.containerDetails1;
            setTabForLeo(prev => ({
                ...prev,
                container_new_1: container_new_1
            }))
            var container_new_2 = completedData.containerDetails2;
            var leo_planned = completedData.work_order_level_data2;
            if (localStorage.getItem('role') == 'tpt') {
                if (localStorage.getItem('plant_code') != '' && localStorage.getItem('plant_code') != undefined && localStorage.getItem('plant_code') != 'undefined') {
                    leo_planned = leo_planned.filter(e => e.transporter_code == localStorage.getItem('plant_code'))
                }
            }
            var leo_modified = completedData.work_order_level_data3;
            if (localStorage.getItem('role') == 'tpt') {
                if (localStorage.getItem('plant_code') != '' && localStorage.getItem('plant_code') != undefined && localStorage.getItem('plant_code') != 'undefined') {
                    leo_modified = leo_modified.filter(e => e.transporter_code == localStorage.getItem('plant_code'))
                }
            }
            setTabForLeo(prev => ({
                ...prev,
                leo_planned: leo_planned,
                leo_modified: leo_modified,
                container_new_2: container_new_2
            }))

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setloadshow("show-n")
    };
    // const gettransportercount=()=>{
    //     var transporter=[]
    //     var data = props.data 
    //     //console.log(data,'1877777777777777777777777777777777')
    //     var containersdata = data.tptContainerData
    //     var trucksdata = data.transporterDetails
    //     var transporterDetails = trucksdata.filter(e => e.tpt_trucks_planned==1||e.tpt_trucks_new==1||e.tpt_trucks_modified==1);
    //     transporter.push(...transporterDetails)
    //     var tpt_trucks_planned = transporterDetails.filter(e=>e.tpt_trucks_planned==1)
    //     var tpt_trucks_new = transporterDetails.filter(e=>e.tpt_trucks_new==1)
    //     var tpt_trucks_modified = transporterDetails.filter(e=>e.tpt_trucks_modified==1)

    //     settabsfortrucks({
    //       tpt_trucks_planned:tpt_trucks_planned,
    //       tpt_trucks_new:tpt_trucks_new,
    //       tpt_trucks_modified:tpt_trucks_modified
    //     })
    //     settranscount(prev => ({
    //       ...prev,
    //       transporterdetails: transporterDetails
    //     }));
    //     setcount(prev=>({
    //       ...prev,
    //       truck_details_count: transporterDetails
    //     }))
    //     //containers data 
    //     var container_new = containersdata.work_order_level_data_container;
    //     var container_new_1 = containersdata.containerDetails1_container;
    //     var container_new_2= containersdata.containerDetails1_container2;
    //     var container_new_3= containersdata.containerDetails1_container3;

    //     var container_planned = containersdata.work_order_level_data_container2;
    //     var container_modified = containersdata.work_order_level_data_container3;
    //     settabsforcontainers({
    //       tpt_containers_new:container_new,
    //       tpt_containers_planned:container_planned,
    //       tpt_containers_modified:container_modified,
    //       container_new_1:container_new_1,
    //       container_new_2:container_new_2,
    //       container_new_3:container_new_3
    //     })
    //     const containerDetails = [...container_new,...container_planned,...container_modified]
    //     transporter.push(...containerDetails)
    //     settranscount(prev => ({
    //       ...prev,
    //       containerdetails: containerDetails,
    //     }));
    //     setcount(prev=>({
    //       ...prev,
    //       truck_details_count: containerDetails
    //     }))
    // }
    const onSelectWON = (e) => {
        setmanualUploadDetails(prev => ({
            ...prev,
            wrno: { label: e.value, value: e.value }
        }))
    }
    const changeHandleDate = (e) => {
        var d = moment(e._d);
        var formattedDate = d.format('DD-MM-YYYY');
        return formattedDate
    }
    const handleDatesSelected = (startDate, endDate) => {
        // Access the selected dates in the parent component
        var wo_release_from_date = startDate.format('DD-MM-YYYY');
        var wo_release_to_date = endDate.format('DD-MM-YYYY')
        setWoReleaseDate({
            wo_release_from_date: wo_release_from_date,
            wo_release_to_date: wo_release_to_date
        })
    };
    const manualbulkUploadForCha = () => {
        setshowAddForm("slide30")
        setoverly("show-m")
    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    const filterData = () => {

    }
    const handleInputfilter = (e) => {
        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
    }
    const onClickCounters = (param) => {
        var filteredData
        if (param == 4) {
            setcheck(4)
            setIsClicked({ all: true })
        }
        else if (param == 1) {
            setcheck(1)
            setIsClicked({ truckDetails: true })
        }
        else if (param == 2) {
            setcheck(2)
            setIsClicked({ containerDetails: true })
        }
        else if (param == 3) {
            setIsClicked({ closed: true })
            setcheck(3)
        }
        else if (param == 5) {
            setIsClicked({ modified: true })
            setcheck(5)
        }
    }
    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const handleFilterOptions = (e) => {
        if (e.value == 'production_date' || e.value == 'wo_release_date' || e.value == 'latest_shipment_date') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setoverly('show-n')
        document.getElementById('stuffing_date').value = '';
    }
    const onsubmitdata = (e) => {
        e.preventDefault()
        //console.log('rupa')
        var data = [{
            work_order_no: manualUploadDetails.wrno.value,
            stuffing_date: manualUploadDetails.stuffing_date,
            remarks_by_ib: manualUploadDetails.remarks_by_ib
        }]
        var rparams = {
            data: data,
        }
        redirectURL.post('/consignments/uploadBulkuploadforIBdata', rparams).then((resp) => {
            //console.log(resp.data, '176')
            if (resp.data.status == 'Success') {
                setshow(true)
                setbasicTitle("Successfully Updated")
                setbasicType("success")
                setloadshow('show-n')
                setoverly('show-n')
                setshowAddForm('')
            }
            else {
                setshow(true)
                // setbasicTitle("Successfully Uploaded")
                setbasicType("failure")
                setloadshow('show-n')
                setoverly('show-n')
                setshowAddForm('')
            }
            //console.log(resp.data)
        })
    }
    const filterDatafordates = () => {
        var from_date = woReleaseDate.wo_release_from_date
        from_date = moment(from_date, 'DD-MM-YYYY')
        var to_date = woReleaseDate.wo_release_to_date
        to_date = moment(to_date, 'DD-MM-YYYY')
        //console.log(from_date, '209')
        //console.log(to_date, '210')
        var filteredData = []
        var selectedOpt = selectedval.dropdownval.value
        if (selectedOpt == 'wo_release_date') {
            filteredData = originalData.filter(item => {
                var itemDate = getHyphenDDMMYYYY(item.wo_release_date)
                itemDate = moment(itemDate, 'DD-MM-YYYY')
                return itemDate.isBetween(from_date, to_date, null, '[]')
            });
        }
        else if (selectedOpt == 'production_date') {
            filteredData = originalData.filter(item => {
                var itemDate = getHyphenDDMMYYYY(item.production_date)
                itemDate = moment(itemDate, 'DD-MM-YYYY')
                return itemDate.isBetween(from_date, to_date, null, '[]')
            });
        }
        else if (selectedOpt == 'latest_shipment_date') {
            filteredData = originalData.filter(item => {
                var itemDate = getHyphenDDMMYYYY(item.latest_shipment_date)
                itemDate = moment(itemDate, 'DD-MM-YYYY')
                return itemDate.isBetween(from_date, to_date, null, '[]')
            });
        }
        setrowData(filteredData)
    }
    const onClickHide = () => {
        setsliderForBulkupload('')
        document.getElementById("uploadfile").value = null
        setoverly('show-n')
    }
    var onclickbulkupload = () => {
        // setsliderForBulkupload("slider-translate")
        setopen(true)
        setoverly('show-m')
    }
    const onCloseModal = () => {
        setopen(false)
        setsliderForBulkupload('')
         setshowGrid(false)
         setoverly('show-n')
     }
    const onSubmitOrderBulkUpload = (event) => {
        event.preventDefault();
        setloadshow('show-m')
        if (file != '') {
            var rparams = {
                data: csvcontent,
            }
            redirectURL.post('/consignments/updateContainerDetails', rparams).then((resp) => {
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Successfully Uploaded")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                    setopen(false)
                    setshowGrid(false)
                    refreshDataNow()
                }
                else {
                    setshow(true)
                     setbasicTitle(resp.data.message)
                    setbasicType("Failure")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                }
                //console.log(resp.data)
            })
        }
    }
    var changeOrderFileHandlerFcrBulk = async (e) => {
        setfile(e.target.files[0])
        var validCols = ["Work Order No","Truck No","Container No", "Pickup Date", "Shipping Seal", "Container Tare Weight", "Container Gross Weight", 'LR No', 'LR Date', 'Driver Mobile No']
        var verifyCols = verifyUploadFileColumns(validCols, e.target.files[0])
        .then(result => {
            //console.log(result);
            setvalidbulkfile(result)
        })
        .catch(error => {
            seterrormsg(error)
        });
        var csvdata = await BulkForceFileHandler(e, [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'Truck No', inputName: 'truck_no', required: true },
            { name: 'Container No', inputName: 'container_no', required: true },
            { name: 'Pickup Date', inputName: 'pick_up_date', required: true },
            { name: 'Shipping Seal', inputName: 'shipping_seal', required: true },
            { name: 'Container Tare Weight', inputName: 'container_tare_weight', required: true },
            { name: 'Container Gross Weight', inputName: 'container_gross_weight', required: true },
            { name: 'LR No', inputName: 'lr_no', required: true },
            { name: 'LR Date', inputName: 'lr_date', required: true },
            { name: 'Driver Mobile No', inputName: 'driver_mobile_no', required: true },
        ])
        //console.log(csvdata, '1234')
        setcsvcontent(csvdata)
        setshowGrid(true)
        setrowData1(csvdata)
    }
    var summaryViewcols = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 140,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Booking No",
            field: "booking_no",
            width: 160,
            filter: true,
            resizable: true,
        },

        {
            headerName: "CHA",
            field: "cha",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Gate Open Date",
            field: "gate_open_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.gate_open_date != "" && params.data.gate_open_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.gate_open_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },

    ]
    var detailedviewcols = [
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Trucks",
            field: "truck_and_courier",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "POD",
            field: "pod",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "PO NO",
            field: "po_no",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quotation",
            field: "quotation",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },


        {
            headerName: "Latest Shipment Date",
            field: "latest_shipment_date",
            width: 160,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.latest_shipment_date != "" && params.data.latest_shipment_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.latest_shipment_date);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Expected Payment Clearance By",
            field: "expected_payment_clearance_by",
            width: 220,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.expected_payment_clearance_by != "" && params.data.expected_payment_clearance_by != undefined) {
                        return getHyphenDDMMYYYY(params.data.expected_payment_clearance_by);
                    }
                    else {
                        return "Not Available";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "HAZ Classification",
            field: "haz_classification",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Assy Config Type",
            field: "assy_config_type",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Payment Term",
            field: "payment_term",
            width: 160,
            filter: true,
            resizable: true,
        },
        // {
        //     headerName: "Tentative Shipping Line",
        //     field: "tentative_shipping_line",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        // },
        // {
        //     headerName: "Tentative Vessel ETD",
        //     field: "tentative_vessel_etd",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_vessel_etd != "" && params.data.tentative_vessel_etd != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_vessel_etd);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        // {
        //     headerName: "Tentative Gate Open Date",
        //     field: "tentative_gate_open_date",
        //     width: 180,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_gate_open_date != "" && params.data.tentative_gate_open_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_gate_open_date);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        // {
        //     headerName: "Tentative Cut Off Date",
        //     field: "tentative_cut_off_date",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_cut_off_date != "" && params.data.tentative_cut_off_date != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_cut_off_date);
        //             }
        //             else {
        //                 return "Not Available";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: "Remarks (By CHA)",
            field: "remarks_by_cha",
            width: 160,
            filter: true,
            resizable: true,
        },
    ]
    const refreshDataNow = (params) => {
        // //console.log("paramss", params)
        onClickCounters(params)
        gettransportercount()
        setReloadData(true);
        // getcloseddata()
        props.refresNow()
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }

    const columnwithDefs = summaryviewcheck == 1 ? summaryViewcols : detailedviewcols
    //console.log(transcount.closed_data, '827')
    return (
        <div class="container-fluid" style={{ padding: '10px' }}>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>
            {props.allcounter == 1 ? "" :
                <div className="form-group d-flex justify-content-between  mt-2 pl-0 mb-0" style={{ right: "-25px" }}>
                    <h5 className="heading ml-50p">
                        <a href="/exportoperationscontroltower" className="txt-dec-none clr333">
                        </a>Transporter Activities
                    </h5>
                   {/* {isClicked.containerDetails==true? <button type="button" className="btn-bulkupload mr-20p" style={{ fontSize: "0.8rem", }} onClick={() => onclickbulkupload()}>
                   <img src={require("../../assets/images/upload.png")} alt="Icon" className="mr-1" />
                   Bulk Upload</button>:''} */}
                </div>}
            <div className={props.allcounter == 1 ? '' : "card mb-20p"} style={props.allcounter === 1 ? {} : { padding: '25px', margin: '20px' }}>
                {props.allcounter == 1 ? '' :
                    <span style={{ textAlign: 'end', color: 'red', fontSize: '12px' }}>* New / Modified</span>}
                <div className="row bghighliter" style={{ marginBottom: "10px" }}>
                    {/* <div className="plantCounterdiv hp" style={{ width: '156px',background: isClicked.all ? 'rgb(64,81,120)' : '' }} onClick={() => onClickCounters('4')}>
                        <img src={isClicked.all?require("../../assets/images/all-white.png"):require("../../assets/images/all.png")} alt="Icon" style={{ height: '25px', margin: '8px' }} />
                        <div className="col-9 txt-center" style={{ padding: '3px',color: isClicked.all ? '#FFFFFF' : '#0664AE' }}>
                            <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '11px' }} >
                                All
                            </h5>
                            <div>{(props.allTransData!='' && props.allTransData!=undefined)?props.allTransData.length:0}</div>
                        </div>

                    </div> */}
                    <div className="plantCounterdiv hp" style={{ width: '156px', background: isClicked.truckDetails ? 'rgb(64,81,120)' : '', cursor: "pointer" }} onClick={() => onClickCounters('1')}>
                        <img src={isClicked.truckDetails ? require("../../assets/images/new-white.png") : require("../../assets/images/new.png")} alt="Icon" style={{ height: '25px', margin: '8px' }} />
                        <div className="col-9 txt-center" style={{ padding: '3px', color: isClicked.truckDetails ? '#FFFFFF' : '#0664AE' }}>
                            <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '11px' }} >
                                Update Trucks Details
                            </h5>
                            <div>{(tabsfortrucks.tpt_trucks_new != '' && tabsfortrucks.tpt_trucks_new != undefined) ? tabsfortrucks.tpt_trucks_new.length : 0} / {(tabsfortrucks.tpt_trucks_modified != '' && tabsfortrucks.tpt_trucks_modified != undefined) ? tabsfortrucks.tpt_trucks_modified.length : 0}</div>
                        </div>

                    </div>
                    {/* <div className="plantCounterdiv hp" style={{ width: '156px', background: isClicked.containerDetails ? 'rgb(64,81,120)' : '', cursor: "pointer" }} onClick={() => onClickCounters('2')}>
                        <img src={isClicked.containerDetails ? require("../../assets/images/new-white.png") : require("../../assets/images/new.png")} alt="Icon" style={{ height: '25px', margin: '8px' }} />
                        <div className="col-9 txt-center" style={{ padding: '3px', color: isClicked.containerDetails ? '#FFFFFF' : '#0664AE' }}>
                            <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '11px' }} >
                                Update Container Details
                            </h5>
                            <div>{(tabsforcontainers.tpt_containers_new != '' && tabsforcontainers.tpt_containers_new != undefined) ? tabsforcontainers.tpt_containers_new.length : 0} / {(tabsforcontainers.tpt_containers_modified != '' && tabsforcontainers.tpt_containers_modified != undefined) ? tabsforcontainers.tpt_containers_modified.length : 0}</div>
                        </div>

                    </div> */}
                    <div className="plantCounterdiv hp" style={{ width: '156px', background: isClicked.closed ? 'rgb(64,81,120)' : '', cursor: "pointer" }} onClick={() => onClickCounters('3')}>
                        <img src={isClicked.closed == 1 ? require("../../assets/images/all-white.png") : require("../../assets/images/all.png")} alt="Icon" style={{ height: '21px', margin: '8px' }} />

                        {/* <img src={isClicked.closed?require("../../assets/images/closed-white.png"):require("../../assets/icons/closed_clr.png")} alt="Icon" style={{ height: '20px', margin: '8px' }} /> */}
                        <div className="col-9 txt-center" style={{ padding: '3px', color: isClicked.closed ? '#FFFFFF' : '#0664AE' }}>
                            <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '11px' }} >
                                Completed
                            </h5>
                            <div>{(transcount.closed_data != '' && transcount.closed_data != undefined) ? transcount.closed_data.length : 0}</div>
                        </div>

                    </div>
                    {/* <div className="plantCounterdiv hp" style={{ width: '156px',background: isClicked.modified ? 'rgb(64,81,120)' : ''  }} onClick={() => onClickCounters('5')}>
                    <img src={isClicked.modified?require("../../assets/images/modified-white.png"):require("../../assets/images/edit.png")} alt="Icon" style={{ height: '23px', margin: '8px' }} />
                        <div className="col-9 txt-center" style={{ padding: '3px',color: isClicked.modified ? '#FFFFFF' : '#0664AE' }}>
                            <h5 className="sptile-tracking" style={{ fontWeight: 'bold', fontSize: '11px' }} >
                                Modified
                            </h5>
                            <div>0</div>
                        </div>

                    </div> */}
                </div>
                <div className="d-flex justify-content-end">
                <div className="form-group">
                {isClicked.containerDetails==true? <button type="button" className="btn-bulkupload mr-20p" style={{ fontSize: "0.8rem", }} onClick={() => onclickbulkupload()}>
                   <img src={require("../../assets/images/upload.png")} alt="Icon" className="mr-1" />
                   Bulk Upload</button>:''}
                </div>
                </div>

                <div className="row">
                    {check == 4 ?
                        <TransallComponent check={4} data={props.allTransData} onRequest={refreshDataNow} />
                        : ''}
                    {check == 1 ?
                        <Updatetrucksdetails data={tabsfortrucks} onRequest={refreshDataNow} />
                        : ''}
                    {check == 2 ?
                        <UpdateContainerdetails data={tabsforcontainers} onRequest={refreshDataNow} />
                        : ''}
                    {check == 3 ?
                        <ClosedComponentForTrans data={tabForLeo} onRequest={refreshDataNow} />
                        : ''}
                </div>

            </div>
            {isClicked.containerDetails==true?
            <Modal open={open} onClose={onCloseModal} classNames={{ modal: 'custom-modal' }} >
            <div className="slide-r-title slider_title_style title-radius">
                        <h6>Bulk Upload</h6>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                        <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            <div className="theme-form col-xl-12 col-lg-12">
                                <form action="" onSubmit={onSubmitOrderBulkUpload}>
                                    <div className="col-xl-12 col-lg-12 row form-group">
                                        <label className="col-xl-12 col-lg-12">Upload File *</label>
                                        <div className="d-flex justify-content-between">
                                            <input
                                                type="file"
                                                name="uploadfile"
                                                id="uploadfile"
                                                className="form-control ml-3"
                                                onChange={changeOrderFileHandlerFcrBulk}
                                                style={{ width: "400px" }}
                                                required
                                            />
                                            <img src={require("../../assets/icons/red-dot.png")} className="img-dot ml-70p mt-8p" /><span className="mt-8p ml-10p">Invalid Date Format</span>
                                        </div>
                                    </div>
                                    {showGrid &&
                                        <div id="idgrid" className="ag-theme-balham m-10p ml-15p bulkGridResolution">
                                            <AgGridReact
                                                // modules={this.state.modules}
                                                columnDefs={columnsforbulk}
                                                // defaultColDef={this.state.defaultColDef}
                                                rowData={rowData1}
                                                domLayout="autoHeight"
                                            />
                                        </div>}
                                    <div className="col-xl-12 col-lg-12 form-group mt-10p">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>
                                </form>
                                {!showGrid && <div className="col-xl-12 col-lg-12 form-group">
                                    <a href={require("../../assets/json/tpt_based_container_update.csv")} target="_blank" rel="noopener noreferrer" className="btn btn-warning">
      Download Template
    </a>
                                </div>}

                                <p style={{ color: 'red' }} className="ml-15p">Note: </p>
                                <p>All Date Fields Should be in 'DD-MM-YYYY' format.</p>

                            </div>
                        </div>
                    </div>
                </Modal>:''}
            <div className={"slide-r " + sliderForBulkupload} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Bulk Upload</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHide} >X</span>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

                        <div className="theme-form col-xl-12 col-lg-12">

                            <form action="" onSubmit={onSubmitOrderBulkUpload}>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                    <input
                                        type="file"
                                        name="uploadfile"
                                        id="uploadfile"
                                        className="form-control"
                                        onChange={changeOrderFileHandlerFcrBulk}
                                        required
                                    />
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/tpt_based_container_update.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                            <p style={{ color: 'red' }}>Note: </p>
                            <p>Stuffing Date should be in 'DD-MM-YYYY' format. <br />
                                Remarks should be in String format.</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className={"sliderBlock2 " + showAddForm} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter Details</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Work Order No: </label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.wrno}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON(e)}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={workordernoList}
                                        />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Stuffing Date*</label>
                                        <Datetime
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="stuffing_date"
                                            value={manualUploadDetails.remarks_by_ib}
                                            dateFormat="DD-MM-YYYY"
                                            id='stuffing_date'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, stuffing_date: changeHandleDate(e) }))}
                                            required />
                                    </div>
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Remarks By IB*</label>
                                        <input
                                            type="text"
                                            name="remarks_by_ib"
                                            // placeholder='Enter Partner Type'
                                            id="inputcode"
                                            value={manualUploadDetails.remarks_by_ib}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, remarks_by_ib: e.target.value }))}
                                            required
                                        />
                                    </div>
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </div>

    )
}
const getDistinctWorkOrders = (records) => {
    // Use Set to store unique workorders
    const uniqueWorkOrdersSet = new Set();

    // Populate the set with unique workorders
    records.forEach(record => {
        uniqueWorkOrdersSet.add(record.workorders);
    });

    // Convert the set back to an array
    const uniqueWorkOrdersArray = Array.from(uniqueWorkOrdersSet);

    return uniqueWorkOrdersArray;
};
const verifyUploadFileColumns = async (validCols, file) => {
    return new Promise((resolve, reject) => {
        if (file !== "" && file !== undefined) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const lines = e.target.result.split('\n');
                const headers = lines[0].split(',');
                const invalidCols = headers.filter(header => !validCols.includes(header.trim()));
                if (invalidCols.length === 0) {
                    resolve(true);
                } else {
                    resolve(`Invalid column(s): ${invalidCols.join(', ')}`);
                }
            };
            reader.readAsText(file);
        } else {
            reject('No file selected');
        }
    });
};
export default TransporterComponent
