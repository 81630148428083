import React, { Component,useState, useEffect, useRef,useReducer } from "react"
import SweetAlert from 'react-bootstrap-sweetalert';

import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';

import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import Select from "react-select";
import SearchIcon from "../../assets/icons/search-icon.svg";
import SubmitBtn from "./submitBtn";
import Datepicker from "./datepicker";
import { getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMM , getDDMMMYYYY,getHyphenDDMMMYYYY, getHyphenDDMMYYYY,getHyphenYYYYMMDD,getHyphenYYYYMMDDHHMM } from '../common/utils';
import moment from "moment";
import Mapcard2 from "./drawmap";
import AcknowledgeBtnComponent from "./acknowledgeBtn";
import UpdateShippingOnBoardBtn from "./updateshippingonboardbtn";
import BulkForceFileHandler from "../layouts/changebulkuploadhandler";
import redirectURL from "../redirectURL";
import ConsignmentTrucksLegs from "./containertruckslegs";
import customNextIcon from "../../assets/icons/next.png";
import customPreviousIcon from "../../assets/icons/back.png";
import customFirstIcon from "../../assets/icons/back_left.png";
import customLastIcon from "../../assets/icons/next_right.png";
import CountryFlagComponent from "./countryFlag";
import {fetchGrid} from "../fetchGridStructure"
import ShippingonboardGrid from "./shippingonboardgrid"
var reducer = (state, action) => {
	switch (action.type) {
		case 'SET_DETAIL_RENDERER_PARAMS':
            //console.log('2020')
			return {
				...state,
				detailCellRendererParams: action.payload,
			};
		// case 'SET_DETAIL_RENDERER_PARAMS_FOR_STOPPAGES':
		// 	//console.log('6767')
		// 	return {
		// 		...state,
		// 		detailCellRendererParams: action.payload,
		// 	};
		default:
			return state;
	}
};
var initialState = {
	detailCellRendererParams: {},
};
const ShippingOnBoardComponent = (props) => {
    const gridApi = useRef(null);
    var [state, dispatch] = useReducer(reducer, initialState);
    // var [gridApi, setGridApi] = useState(null);
    var [gridColumnApi, setgridColumnApi] = useState(null)
    var gridRef = useRef();
    var [summaryviewcheck, setsummaryviewcheck] = useState(1)
    var [detailedviewcheck, setdetailedviewcheck] = useState(0)
    var [selected_row,setselected_row] = useState({})
    var [rowData, setrowData] = useState([])
    var [originalData, setoriginalData] = useState([])
    var [checkinput, setcheckinput] = useState(1)
    var [checkForDateFilter, setcheckForDateFilter] = useState(0)
    var [loadshow, setloadshow] = useState('show-n')
    const [rowData1, setrowdata1] = useState([])
    var [overly, setoverly] = useState('show-n')
    var [sliderForBulkupload, setsliderForBulkupload] = useState('')
    var [file, setfile] = useState('')
    var [csvcontent, setcsvcontent] = useState("")
    var [show, setshow] = useState(false)
    var [basicTitle, setbasicTitle] = useState('')
    var [basicType, setbasicType] = useState('')
    var [selectedval, setselectedval] = useState({
        dropdownval: '',
        inputval: ''
    })
    var [check, setcheck] = useState(0)
    var [woReleaseDate, setWoReleaseDate] = useState({
        factory_dispatch_date_from_date: '',
        factory_dispatch_date_to_date: '',
        leo_from_date:'',
        leo_to_date:''
    })
    var [counters, setCounters] = useState({
        vessel_avai: []
    })
    var [file1,setFileValue]=useState('')
    const [isClicked, setIsClicked] = useState({
        vessel_avail: false,
        uploadDo: false
    });
    
    const [showAddForm, setshowAddForm] = useState('')
    const [showAddForm1, setshowAddForm1] = useState('')
    const [showAddForm2, setshowAddForm2] = useState('') 
    const [showAddForm3, setshowAddForm3] = useState('')
    const [manualUploadDetails, setmanualUploadDetails] = useState({
        wrno: '',
        container:'',
        shipping_on_board_date:'',
        bl_no:'',
        bl_date:'',
        bl_file:'',
        gate_in_date_time:''
    })
    var [stateForMap, setStateForMap] = useState({
        sliderRouteTranslate: '',
        showDiv: 'show-n',
        mapinfo: {
            coords: [
                // { lat: 8.76735, lng: 78.13425 },
                // { lat: 18.76735, lng: 88.13425 },
              ],
        },
        mapdata:[],
        dealer: '',
        consignment_code: '',
        truck_no:'',
        container_no:'',
        maptruckno: '',
        routeTruck: '',
        sidebarSubHeader: '',
        rownode: '',
        consigner_coordinates: '',
        consignee_coordinates: '',
        map_type_of_tracking: '',
        baseRouteCoords: '',
        mapHeight: "94vh",
    })
    var [rerender, setRerender] = useState(true);
    var [containerLegsData , setContainerLegsData] = useState([])
    var [workordernoList, setworkOrderList] = useState([])
    var [workordernoList1, setworkOrderList1] = useState([])
    var [workordernoList2, setworkOrderList2] = useState([])
    var [workordernoList3, setworkOrderList3] = useState([])

    var [containernoList, setContainerNoList] = useState([])
    var [containersforgatein,setcontainersforgatein] = useState([])
    var [containersforshipping,setcontainersforshipping] = useState([])
    var [containersforbl,setcontainersforbl] = useState([])
    var [containernoList3, setContainerNoList3] = useState([])
    var [stateForMap, setStateForMap] = useState({
        sliderRouteTranslate: '',
        showDiv: 'show-n',
        mapinfo: {
            coords: [
                // { lat: 8.76735, lng: 78.13425 },
                // { lat: 18.76735, lng: 88.13425 },
              ],
        },
        dealer: '',
        consignment_code: '',
        truck_no:'',
        container_no:'',
        maptruckno: '',
        routeTruck: '',
        sidebarSubHeader: '',
        rownode: '',
        consigner_coordinates: '',
        consignee_coordinates: '',
        map_type_of_tracking: '',
        baseRouteCoords: '',
        mapHeight: "94vh",
    })
    var [tabforgatein,settabforonboard] = useState({
        onboard_new: [],
        onboard_planned: [],
        container_new_1:[],
        container_new_2:[]
    })
    var [classForTab, setclassforTab] = useState({
        planned: 0,
        new: 1,
        modified: 0,
    })
    var [workOrder,setWorkOrder] = useState([])
    var [work_order2,setwork_order2]=useState([])
    var [work_order3,setwork_order3]=useState([])
    const [summaryViewcols, setsummaryViewcols] = useState([
        {
            headerName: "",
            field: "containerLegs",
            width: 60,
            pinned: 'left',
            cellRenderer: 'ConsignmentTrucksLegs'
        },
        {
            headerName: "Ship To Country",
            field: "ship_to_country",
            width: 120,
            filter: true,
            resizable: true,
            // cellRenderer:CountryFlagComponent
        },
        {
            headerName: "POD",
            field: "pod",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Work Order No",
            field: "work_order_no",
            width: 130,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Production Date",
            field: "production_date",
            width: 150,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.production_date != "" && params.data.production_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.production_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        // {
        //     headerName: "Tentative Vessel ETD",
        //     field: "tentative_vessel_etd",
        //     width: 160,
        //     filter: true,
        //     resizable: true,
        //     valueGetter: function (params) {
        //         try {
        //             if (params.data.tentative_vessel_etd != "" && params.data.tentative_vessel_etd != undefined) {
        //                 return getHyphenDDMMYYYY(params.data.tentative_vessel_etd);
        //             }
        //             else {
        //                 return "";
        //             }
        //         }
        //         catch (e) { }
        //     },
        // },
        {
            headerName: "Stuffing Date",
            field: "stuffing_date",
            width: 150,
            filter: true,
            resizable: true,
            editable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.stuffing_date != "" && params.data.stuffing_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.stuffing_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Brand",
            field: "brand",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Brand Variant",
            field: "brand_variant",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Quantity",
            field: "quantity",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "40ft Containers",
            field: "ft40_containers",
            width: 150,
            filter: true,
            resizable: true,
        },
        {
            headerName: "20ft Containers",
            field: "ft20_containers",
            width: 150,
            filter: true,
            resizable: true,
            
        },
        {
            headerName: "Pendency",
            field: "pendency_shipping_on_board_date",
            width: 140,
            filter: true,
            resizable: true,
            hide:true,
            // valueGetter:function(params){
            //     if(params.data.pendency_bl_details!='' && params.data.pendency_bl_details!=undefined){
            //         return params.data.pendency_bl_details
            //     }
            //     else{
            //         return params.data.container_count
            //     }
            // }
        },
        {
            headerName: "Trucks",
            field: "truck_and_courier",
            width: 130,
            filter: true,
            resizable: true,
        },
        
        {
            headerName: "Plant",
            field: "plant",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "CHA Name",
            field: "cha_name",
            width: 200,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Plant Name",
            field: "plant_name",
            width: 120,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Transporter Name",
            field: "tpt_name",
            width: 160,
            filter: true,
            resizable: true,
        },

        {
            headerName: "LEO Date",
            field: "leo_date",
            width: 130,
            filter: true,
            resizable: true,
            valueGetter: function (params) {
                try {
                    if (params.data.leo_date != "" && params.data.leo_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.leo_date);
                    }
                    else {
                        return "";
                    }
                }
                catch (e) { }
            },
        },
        {
            headerName: "Booking No",
            field: "booking_no",
            width: 130,
            filter: true,
            resizable: true,
            editable: true,
        },
        {
            headerName: "Shipping Line",
            field: "shipping_line",
            width: 250,
            filter: true,
            resizable: true,
            editable: true,
        },
        {
            headerName: "Vessel Name",
            field: "vessel_name",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "3rd Party Inspection Req",
            field: "third_party_inspection_req",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "Port Of Loading",
            field: "port_of_loading",
            width: 190,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type Description",
            field: "vin_type_description",
            width: 160,
            filter: true,
            resizable: true,
        },
        {
            headerName: "VIN Type",
            field: "vin_type",
            width: 160,
            filter: true,
            resizable: true,
        },
       
    ])
    useEffect(()=>{
        if (showAddForm1 ==='slide45'){
           document.body.style.overflow = 'hidden';
        }else{
           document.body.style.overflow = 'auto';
        }
        return ()=>{
           document.body.style.overflow = 'auto';
        }
      }, [showAddForm1])
    
    useEffect(() => {
        if (gridApi.current) {
            getUserGridState();
        }
        loadDateTimeScript();
    }, [gridApi]);
    // useEffect(() => {
    //     if(rerender){
    //         loadDateTimeScript();
    //         gateindata();
    //     }
    // }, [rerender]);
    useEffect(() => {
        if (gridApi.current && gridApi.current.api) {
            gridApi.current.api.forEachNode((node) => {
                if (node.expanded) {
                    node.setExpanded(false);  // Collapse the row if expanded
                }
            });
        } else {
            console.log("gridApi is not initialized");
        }
        loadDateTimeScript();
        // gateindata();     
        let shippingData = props.data.rawData   || {} 
        try {
            var onboard_new = shippingData.work_order_level_data1;
            var container_new_1 = shippingData.containerDetails1;
            var container_new_2 = shippingData.containerDetails2;
            var onboard_planned = shippingData.work_order_level_data2;
            var shipping_wrno = shippingData.unique_work_order_no_array3
            if (localStorage.getItem('role') == 'cha') {
                if (localStorage.getItem('plant_code') != '' && localStorage.getItem('plant_code') != undefined) {
                    onboard_planned = onboard_planned.filter(e => e.cha == localStorage.getItem('plant_code'))
                    onboard_new = onboard_new.filter(e => e.cha == localStorage.getItem('plant_code'))
                }
            }
            if (onboard_new != '' && onboard_new != undefined) {
                onboard_new = sortByCreatedDate(onboard_new)
            }
            if (onboard_planned != '' && onboard_planned != undefined) {
                onboard_planned = sortByCreatedDate(onboard_planned)
            }
            var boardData
            if (classForTab.new) {
                var boardData = onboard_new
            }
            else if (classForTab.planned) {
                var boardData = onboard_planned
            }
            setrowData(boardData)
            setoriginalData(boardData)
            setContainerLegsData(container_new_1);
            settabforonboard({
                onboard_new: onboard_new,
                onboard_planned: onboard_planned,
                container_new_2: container_new_2,
                container_new_1: container_new_1
            })
            var work_order1 = []
            // var containers1 = []
            onboard_new.map((e) => {
                work_order1.push({ label: e.work_order_no, value: e.work_order_no })
            })
            //console.log(work_order1,'work_order1work_order1')
            setwork_order2(work_order1)
            // setRerender(!rerender)
        }
        catch (e) { }
    }, [props.data]);
    useEffect(() => {
        if (props.check == 4) {
          const validCols = ["Work Order No","Truck No","Container No", "Shipping On Board Date"];
          const bulkUploadFields = [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'Truck No', inputName: 'truck_no', required: true },
            { name: 'Container No', inputName: 'container_no', required: true },
            { name: 'Shipping On Board Date', inputName: 'shipping_on_board_date', required: true },
        ]
          const apiForUpload = 'updateShippingOnBoardDetails';
          const csvfilename = '../../assets/json/cha_based_shipping_onboard.csv'
          const columndefs = [
            {
                headerName: "Work Order No",
                field: "work_order_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Container No",
                field: "container_no",
                width: 120,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Shipping On Board Date",
                field: "shipping_on_board_date",
                width: 170,
                filter: true,
                resizable: true,
                editable: (params) => {
                    const date = params.data.shipping_on_board_date;
                    // Check if date is in DD-MM-YYYY format
                    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
                    return !(date && datePattern.test(date)); // Editable if date is invalid
                },
                valueGetter: (params) => {
                    const date = params.data.shipping_on_board_date;
                    // Check if date is in DD-MM-YYYY format
                    const datePattern = /^\d{2}-\d{2}-\d{4}$/;
                    if (date && datePattern.test(date)) {
                        return date;
                    } else {
                        return "Invalid Date Format";
                    }
                },
                cellStyle: (params) => {
                    const date = params.value;
                    // Apply red color if the date format is invalid
                    if (date === "Invalid Date Format") {
                        return { background: 'red', color: 'black' };
                    }
                    return null;
                }
            },
           
          ]
          props.uploadBulkData(validCols, bulkUploadFields, apiForUpload,columndefs);
          props.onRequest()
        //   setRerender(!rerender)
        }
      }, [props.check]);

    var gateindata = ()=>{
        props.onRequest()
        // redirectURL.post('/consignments/getshippingonboarddetails').then((resp3) => {
        //     try{
        //         var onboard_new = resp3.data.work_order_level_data1;
        //         var container_new_1 = resp3.data.containerDetails1;
        //         var container_new_2= resp3.data.containerDetails2;
        //         var onboard_planned = resp3.data.work_order_level_data2;
        //         var shipping_wrno = resp3.data.unique_work_order_no_array3 
        //         if(localStorage.getItem('role')=='cha'){
        //             if(localStorage.getItem('plant_code')!='' && localStorage.getItem('plant_code')!=undefined){
        //                 onboard_planned = onboard_planned.filter(e=>e.cha==localStorage.getItem('plant_code'))
        //                 onboard_new = onboard_new.filter(e=>e.cha==localStorage.getItem('plant_code'))
        //             }
        //         }
        //         if(onboard_new!='' && onboard_new!=undefined){
        //             onboard_new = sortByCreatedDate(onboard_new)
        //         }
        //         if(onboard_planned!='' && onboard_planned!=undefined){
        //             onboard_planned = sortByCreatedDate(onboard_planned)
        //         }
        //         var boardData
        //         if (classForTab.new) {
        //             var boardData = onboard_new
        //         }
        //         else if (classForTab.planned) {
        //             var boardData = onboard_planned
        //         }
        //         setrowData(boardData)
        //         setoriginalData(boardData)
        //         setContainerLegsData(container_new_1);
        //         settabforonboard({
        //            onboard_new: onboard_new,
        //            onboard_planned: onboard_planned,
        //            container_new_2:container_new_2,
        //            container_new_1:container_new_1
        //        })
        //        var work_order1 = []
        //        // var containers1 = []
        //        onboard_new.map((e)=>{
        //            work_order1.push({label:e.work_order_no,value:e.work_order_no})
        //        })
        //        //console.log(work_order1,'work_order1work_order1')
        //        setwork_order2(work_order1)
        //        setRerender(!rerender)
        //     }
        //     catch(e){}
        //  })
    }
    var typeofselection = [
        { label: 'Ship To Country', value: 'ship_to_country' },
        { label: 'POD', value: 'pod' },
        { label: 'Plant', value: 'plant' }, { label: 'Factory Dispatch Date', value: 'factory_dispatch_date'}, { label: 'LEO Date', value: 'leo_date'} 
    ]

    const showdata = (e) => {
        if (e == 'detailed view') {
            setdetailedviewcheck(1)
            setsummaryviewcheck(0)
        }
        else if (e == 'summary view') {
            setdetailedviewcheck(0)
            setsummaryviewcheck(1)
        }
    }
    const changeHandleDate = (e) => {
        var d = moment(e._d);
        var formattedDate = d.format('DD-MM-YYYY');
        return formattedDate
    }
    const onClickshippingonboard=(e)=>{
        setselected_row(e.data)
        var row = tabforgatein.container_new_1 
        row = row.filter(f=>f.work_order_no==e.data.work_order_no)
        setrowdata1(row)
        setshowAddForm1("slide45")
        setoverly("show-m")
    }
    const handleFilterOptions = (e) => {
        if (e.value == 'factory_dispatch_date') {
            setcheckForDateFilter(1)
            setcheckinput(0)
        }
        else if(e.value == "leo_date"){
            setcheckForDateFilter(2)
            setcheckinput(0)
        }
        else {
            setcheckinput(1)
            setcheckForDateFilter(0)
        }
        setselectedval(prev => ({
            ...prev,
            dropdownval: e
        }))
    }
    const manualbulkUploadForCha = () => {
        setshowAddForm("slide30")
        setoverly("show-m")
    }
    const manualbulkUploadForCha1 = () => {
        setshowAddForm1("slide30")
        setoverly("show-m")
    }
    const manualbulkUploadForCha2 = () => {
        setshowAddForm2("slide30")
        setoverly("show-m")
    }
    const manualbulkUploadForCha3 = () => {
        setshowAddForm3("slide30")
        setoverly("show-m")
    } 

    const handleDatesSelected = (startDate, endDate) => {
        // Access the selected dates in the parent component
        var factory_dispatch_date_from_date = startDate.format('DD-MM-YYYY');
        var factory_dispatch_date_to_date = endDate.format('DD-MM-YYYY')
        setWoReleaseDate({
            factory_dispatch_date_from_date: factory_dispatch_date_from_date,
            factory_dispatch_date_to_date: factory_dispatch_date_to_date
        })
    };
    const handleDatesSelected1 =(startDate,endDate) =>{
        var leo_from_date = startDate.format('DD-MM-YYYY');
        var leo_to_date = endDate.format('DD-MM-YYYY')
        setWoReleaseDate({
            leo_from_date: leo_from_date,
            leo_to_date: leo_to_date
        })
    }
    const handleInputfilter = (e) => {
        setselectedval(prev => ({
            ...prev,
            inputval: e.target.value
        }))
    }
    const filterData = () => {
        var typeoffield = selectedval.dropdownval.value
        var val = selectedval.inputval
        if(val!='' && val!=undefined){
            if (typeoffield == 'ship_to_country') {
                var filteredData = originalData.filter(item => item.ship_to_country == val)
            }
            else if (typeoffield == 'pod') {
                var filteredData = originalData.filter(item => item.pod == val)
            }
            else if (typeoffield == 'plant') {
                var filteredData = originalData.filter(item => item.plant == val)
            }
            else if (typeoffield == 'brand') {
                var filteredData = originalData.filter(item => item.brand == val)
            }
            else if (typeoffield == 'variant') {
                var filteredData = originalData.filter(item => item.variant == val)
            }
            //console.log(filteredData)
            setrowData(filteredData)
        }
        else{
            setbasicTitle('Please Enter Value')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }
    const onShowShipmentLegRouteMap = async (e) => {
        var params = {
            work_order_no: e.work_order_no,
            truck_no: e.truck_no,
            container_no: e.container_no
        };
        setloadshow('show-m');
        setoverly('show-m');

        try {
            const resp = await redirectURL.post('/consignments/getmapdata', params);
            //console.log(resp.data);
            var gps_route = resp.data.gps_route.coords;
            if (!Array.isArray(gps_route)) {
                gps_route = gps_route.replace(/NaN/g, "0");
                gps_route = JSON.parse(gps_route);
            }
            if (!gps_route || gps_route.length === 0) {
                setshow(true);
                setbasicTitle('No GPS Data Available');
                setbasicType('danger');
                setloadshow('show-n');
                setoverly('show-n');

            } else {
                var allCoordinates = [];
                var allldbcoords = [];
                var gps_route =gps_route;
                var ldb_data = resp.data.ldb_route
                var ldb_route = ldb_data.ldb_route;

                //console.log('gps_route', gps_route);
                //console.log('ldb_route', ldb_route);
                if (ldb_route != '' && ldb_route != undefined) {
                    if (ldb_route.length > 0) {
                        ldb_route = JSON.parse(ldb_route)
                        ldb_route.forEach((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;

                            allldbcoords.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                        //console.log('ldb_route is not an array or is empty.');
                    }
                }
                //console.log(gps_route, 'gps_routegps_route')
                if (gps_route != '' && gps_route != undefined) {
                    if (gps_route.length > 0) {
                        //console.log(typeof gps_route, 'gps_routegps_route')
                        gps_route.map((e) => {
                            var lt = e.latitude || e.lat || 0;
                            var ln = e.longitude || e.lng || 0;
                            allCoordinates.push({
                                lat: lt,
                                lng: ln,
                            });
                        });
                    } else {
                        //console.log('gps_route is not an array or is empty.');
                    }
                }

                var truck_no;
                if (gps_route.length > 0) {
                    truck_no = gps_route[0].truck_no;
                }
                //console.log(allCoordinates, 'allCoordinates');
                setoverly('show-m')
                setStateForMap((prev) => ({
                    ...prev,
                    sliderRouteTranslate: "slider-translate-75p",
                    showDiv: 'show-m',
                    container_no: truck_no,
                    mapinfo: {
                        ...state.mapinfo,
                        coords: allCoordinates,
                        ldb_route: allldbcoords
                    },
                    mapdata:resp.data.gps_route
                }));
                setloadshow('show-n');
            }
        } catch (error) {
            console.error(error);
        }
    };
    // const onShowShipmentLegRouteMap = async (e) => {
    //     var params = {
    //       work_order_no: e.work_order_no,
    //       truck_no: e.truck_no,
    //       container_no: e.container_no
    //     };
      
    //     setloadshow('show-m');
    //     setoverly('show-m');
      
    //     try {
    //       const resp = await redirectURL.post('/consignments/getmapdata', params);
    //       //console.log(resp.data);
    //       var records = resp.data.gps_df;
    //       //console.log(records, '342');
      
    //       if (!Array.isArray(records)) {
    //         records = records.replace(/NaN/g, "0");
    //         records = JSON.parse(records);
    //       }
    //       //console.log(records, '169');
    //       if (!records || records.length === 0) {
    //         setshow(true);
    //         setbasicTitle('No GPS Data Available');
    //         setbasicType('danger');
    //         setloadshow('show-n');
    //         setoverly('show-n');

    //       } else {
    //         var data = records[0];
    //         var allCoordinates = [];
    //         var allldbcoords = [];
    //         var gps_route = data.gps_route;
    //         var ldb_route = data.ldb_route;
            
    //         //console.log( 'gps_route', gps_route); 
    //         //console.log( 'ldb_route', ldb_route);
    //         if(ldb_route!='' && ldb_route!=undefined){
    //             if (ldb_route.length > 0) {
    //                 ldb_route=JSON.parse(ldb_route)
    //                 ldb_route.forEach((e) => {
    //                     var lt = e.latitude || e.lat || 0;
    //                     var ln = e.longitude || e.lng || 0;
                
    //                     allldbcoords.push({
    //                         lat: lt,
    //                         lng: ln,
    //                     });
    //                 });
    //             } else {
    //                 //console.log('ldb_route is not an array or is empty.');
    //             }
    //         }
    //         //console.log(gps_route,'gps_routegps_route')
    //         if(gps_route!='' && gps_route!=undefined){
    //             if (gps_route.length > 0) {
    //                 //console.log(typeof gps_route,'gps_routegps_route')
    //                 gps_route=JSON.parse(gps_route)
    //                 gps_route.map((e) => {
    //                     var lt = e.latitude || e.lat || 0; 
    //                     var ln = e.longitude || e.lng || 0; 
    //                     allCoordinates.push({
    //                         lat: lt,
    //                         lng: ln,
    //                     });
    //                 });
    //             } else {
    //                 //console.log('gps_route is not an array or is empty.');
    //             }
    //         }
            
    //         var truck_no;
    //         if (records.length > 0) {
    //           truck_no = records[0].truck_no;
    //         }
    //         //console.log(allCoordinates, 'allCoordinates');
    //             setoverly('show-m')
    //           setStateForMap((prev) => ({
    //             ...prev,
    //             sliderRouteTranslate: "slider-translate-60p",
    //             showDiv: 'show-m',
    //             container_no: truck_no,
    //             mapinfo: {
    //               ...state.mapinfo,
    //               coords: allCoordinates,
    //               ldb_route:allldbcoords
    //             },
    //           }));
    //         setloadshow('show-n');
    //       }
    //     } catch (error) {
    //       console.error(error);
    //     }
    //   };
    var onCloseRouteDiv = () => {
        setStateForMap(prevState => ({
            showDiv: 'show-n',
            sliderRouteTranslate: "",
        }))
        setoverly('show-n')
        setloadshow('show-n')
    }
    const getUserGridState = () => {
        redirectURL.post("/consignments/usergridstates", { screenurl: '/shippingonboardnew' })
            .then(async (resp) => {
                //console.log("resp.data0999", resp.data)
                restoreGridStates(resp.data);
                var grid = resp.data;
                var oCols= fetchGrid(summaryViewcols, grid[0].gridcolumns)
                //console.log("oCols",oCols)
            })
    }
    const restoreGridStates = (griddata) => {
        try {
            if (griddata.length > 0) {
                var oCols= fetchGrid(summaryViewcols, griddata[0].gridcolumns)
                setsummaryViewcols(oCols);
            }
        } catch (e) {
            //console.log(e);
        }
    };
const onGridReady = params => {
    gridApi.current = params;
    // gridColumnApi.current = params.columnApi;
    getUserGridState();
};


    const onGridState = () => {
        ////console.log(gridApi);

        /*Get  Current Columns State and Store in this.colState */
        const colState = gridApi.current.columnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        const rowGroupState = gridApi.current.columnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        const pivotColumns = gridApi.current.columnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */

        window.filterState = gridApi.current.api.getFilterModel();
        gridApi.current.api.setFilterModel(window.filterState);
        getUserGridState();


    }
    var onClickSaveGridState = () => {
        try {
            // //console.log("SaveGrid", gridApi.current.getSortModel())
            window.colState = gridApi.current.columnApi.getColumnState();
            window.groupState = gridApi.current.columnApi.getColumnGroupState();
            // window.sortState = gridApi.current.columnApi.getSortModel();
            // window.filterState = gridApi.current.api.getFilterModel();
            var screenpage = '/shippingonboardnew'
            let reqparams = {
                gridcolumns: window.colState,
                gridgroup: window.groupState,
                gridcolsort: window.sortState,
                gridcolfilter: window.filterState,
                userId: localStorage.getItem("userid"),
                screenurl: '/shippingonboardnew',
                screentitle: screenpage
            }
            //console.log("reqparams after save grid", reqparams);
            redirectURL.post("/consignments/saveGridStates", reqparams)
                .then((response) => {
                    //console.log("response forr", response)
                    // if(response.data.status === "200"){
                    setshow(true)
                    setbasicTitle("Successfully saved grid layout")
                    setbasicType("success")
                    // setState1({
                    // 	screenurl: window.location.pathname,
                    // 	screentitle: screenpage
                    // })
                    setTimeout(() => {
                        getUserGridState();
                    }, 2000)
                    // getUserGridState();
                    // }
                })
                .catch(function (e) {
                    //console.log("Error ", e)
                })
            //console.log('hit')
        }
        catch (e) {
            //console.log(e)
        }
    }
    const onclickTab = (e) => {
        if (e == 'New') {
            setclassforTab({
                new: 1,
                planned: 0,
                modified: 0
            })
            setrowData(tabforgatein.onboard_new)
            setoriginalData(tabforgatein.onboard_new)
            props.setcheckforbulk(true)
        }
        else if (e == 'Planned') {
            setclassforTab({
                new: 0,
                planned: 1,
                modified: 0
            })
            setrowData(tabforgatein.onboard_planned)
            setoriginalData(tabforgatein.onboard_planned)
            props.setcheckforbulk(false)
        }
    }
    const filterDatafordates = () => {
        var from_date = woReleaseDate.factory_dispatch_date_from_date
        var to_date = woReleaseDate.factory_dispatch_date_to_date
        if(from_date!='' && from_date!=undefined && from_date!=null && to_date!='' && to_date!=undefined&&to_date!=null){
            from_date = moment(from_date, 'DD-MM-YYYY')
            to_date = moment(to_date, 'DD-MM-YYYY')
            var filteredData = rowData.filter(item => {
                var itemDate = getHyphenDDMMYYYY(item.wo_release_date)
                itemDate = moment(itemDate, 'DD-MM-YYYY')
                return itemDate.isBetween(from_date, to_date, null, '[]')
            });
            setrowData(filteredData)
        }
        else{
            setbasicTitle('Please Select Start Date and End date')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }
    const filterDatafordates1 = () => {
        var from_date = woReleaseDate.leo_from_date
        var to_date = woReleaseDate.leo_to_date
        if(from_date!='' && from_date!=undefined && from_date!=null && to_date!='' && to_date!=undefined&&to_date!=null){
            from_date = moment(from_date, 'DD-MM-YYYY')
            to_date = moment(to_date, 'DD-MM-YYYY')
            var filteredData = rowData.filter(item => {
                var itemDate = getHyphenDDMMYYYY(item.wo_release_date)
                itemDate = moment(itemDate, 'DD-MM-YYYY')
                return itemDate.isBetween(from_date, to_date, null, '[]')
            });
            setrowData(filteredData)
        }
        else{
            setbasicTitle('Please Select Start Date and End date')
            setbasicType('danger')
            setoverly('show-n')
            setshow(true)
        }
    }
    const getRowClass = (params)=>{
        //console.log(params,'185')
        if(params.data.cha_leo_modified==1||params.data.cha_reporting_modified==1||params.data.shipping_modified==1||params.data.at_plant_modified==1||params.data.ib_plant_gate_in_modified==1||params.data.tpt_container_modified==1||params.data.tpt_trucks_modified==1||params.data.ib_tpt_modified==1||params.data.modified_plant==1||params.data.cha_vessel_modified==1||params.data.ib_stuffing_modified==1||params.data.cha_do_modified==1){
            return { backgroundColor: '#FFB9B9' }; 
        }
        return null
    }
    const onClickAcknowledgeBtn=(rowdata)=>{
        var row = rowdata.data 
        var params = {work_order_no:row.work_order_no,unsetField:'cha_do_modified',setField:'cha_do_planned',record:row}
        redirectURL.post('/consignments/acknowledgeData',params).then((resp)=>{
            if(resp.data.status=='Success'){
                setshow(true)
                    setbasicTitle("Acknowledgement successful")
                    setbasicType("success")
                    props.onRequest()
                    props.onReloadData(true);
            }
        })
    }
    var onClickShowShipmentLegs = async (rownode) => {
        var commentHeaders = [
            {
                headerName: "",
                field: "",
                resizable: true,
                width: 60,
                filter: true,
                // cellRendererFramework: LegRouteActiveTruckMap,
                cellRendererFramework: (params) => {
                    return (
                        <div>
                            <button onClick={() => onShowShipmentLegRouteMap(params.data)} className="custom-btn label label-success">
                                <i className="icofont icofont-map-pins f20"></i>
                            </button>
                        </div>
                    );
                },
            },
            // {
            //     headerName: 'Requirement Id',
            //     field: "requirement_id",
            //     resizable: true,
            //     width: 180,
            //     filter: true
            // },
            {
                headerName: 'Work Order No',
                field: "work_order_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Truck No',
                field: "truck_no",
                resizable: true,
                width: 180,
                filter: true
            },
            {
                headerName: 'Container No',
                field: "container_no",
                resizable: true,
                width: 180,
                filter: true
               
            },
            // {
            //     headerName: 'Pickup Date',
            //     field: "pick_up_date",
            //     resizable: true,
            //     width: 180,
            //     filter: true,
               
            //     valueGetter: function (params) {
            //         try {
            //             if (params.data.pick_up_date != "" && params.data.pick_up_date != undefined) {
            //                 return getHyphenDDMMYYYY(params.data.pick_up_date);
            //             } else {
            //                 return "";
            //             }
            //         } catch (e) {
            //             // Handle the exception if needed
            //         }
            //     },
               
            // },
            // {
            //     headerName: 'Shipping Seal',
            //     field: "shipping_seal",
            //     resizable: true,
            //     width: 180,
            //     filter: true,
            
            // },
            {
                headerName: 'Invoice No',
                field: "invoice_no",
                resizable: true,
                width: 180,
                filter: true,
                editable:false,
                
            },
            {
                headerName: 'Invoice Date',
                field: "invoice_date",
                resizable: true,
                width: 180,
                filter: true,
                editable:false,
                cellEditor:DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.invoice_date != "" && params.data.invoice_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.invoice_date);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'RFID',
                field: "rfid",
                resizable: true,
                width: 180,
                filter: true,
                editable:false,
            },
            {
                headerName: 'Plant Gate Out Time',
                field: "gate_out_time",
                resizable: true,
                width: 180,
                filter: true,
                editable:false,
                cellEditor:DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_out_time != "" && params.data.gate_out_time != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.gate_out_time);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: 'Reporting Time At Parking Plaza',
                field: "reporting_at_parking",
                resizable: true,
                width: 220,
                filter: true,
                editable:false,
                cellEditor:DateEditor,
                valueGetter: function (params) {
                    try {
                        if (params.data.reporting_at_parking != "" && params.data.reporting_at_parking != undefined) {
                            return getHyphenDDMMYYYYHHMM(params.data.reporting_at_parking);
                        } else {
                            return "";
                        }
                    } catch (e) {
                        // Handle the exception if needed
                    }
                },
                comparator: dateComparator1,
            },
            {
                headerName: "Port Gate-In",
                field: "gate_in_time_port",
                width: 180,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.gate_in_time_port != "" && params.data.gate_in_time_port != undefined) {
                            return getHyphenDDMMYYYY(params.data.gate_in_time_port);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                },
            },
            {
                headerName: "Shipping On Board Date",
                field: "shipping_on_board_date",
                width: 200,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.shipping_on_board_date != "" && params.data.shipping_on_board_date != undefined) {
                            return getHyphenDDMMYYYY(params.data.shipping_on_board_date);
                        }
                        else {
                            return "";
                        }
                    }
                    catch (e) { }
                },
            },
        ]
        var setdata = {
            detailGridOptions: {
                columnDefs: commentHeaders,
                overlayNoRowsTemplate: 'No rows to show',
    
            },
            getDetailRowData: async function (param) {
                param.successCallback([]);
                var row = param.data
                var parameter = {
                    work_order_no:row.work_order_no
                }
                // await redirectURL.post("/consignments/truckslegsdata",parameter).then(async (response) => {
                //     //console.log(response.data,'response')
                //     var records = response.data 
                    // var records = containerLegsData.filter(e =>e.work_order_no==row.work_order_no)
                    var records = tabforgatein.container_new_1
                    if(classForTab.new){
                        var data = tabforgatein.container_new_1
                        //console.log(data,'518')
                        if(data!='' && data!=undefined){
                            records = data.filter(e=>row.work_order_no==e.work_order_no)
                        }
                    }
                    else if(classForTab.planned){
                        var data = tabforgatein.container_new_2
                        if(data!='' && data!=undefined){
                            records = data.filter(e=>row.work_order_no==e.work_order_no)
                        }                }
                    param.successCallback(records);
                // }).catch(function (error) {
                //     //console.log(error);
                // })
            },
            masterDetail: true
        }
        dispatch({ type: 'SET_DETAIL_RENDERER_PARAMS', payload: setdata });
            setTimeout(() => {
                if (rownode.column.colDef.field == 'containerLegs') {
                    rownode.node.setExpanded(!rownode.node.expanded);
                   
                }
                else {
    
                    rownode.node.setExpanded(false);
                }
            }, 0)
    }
    const onsubmitdata = (e) => {
        var updatedData = e
        var data = []
        updatedData.map((e) => {
            if(e.shipping_on_board_date!=''&&e.shipping_on_board_date!=undefined){
                var updated_date = moment.parseZone(e.shipping_on_board_date).format('DD-MM-YYYY')
                data.push({ 'work_order_no': e.work_order_no, 'container_no': e.container_no, 'truck_no': e.truck_no, 'shipping_on_board_date':updated_date })
            }
        })

        var params ={
            data: data
        }

        redirectURL.post('/consignments/updateShippingOnBoardDetails', params).then((resp)=>{
            if (resp.data.status == 'Success') {
                setshow(true)
                setbasicTitle("Shipping Onboard updated Successfully")
                setbasicType("success")
                setloadshow('show-n')
                setoverly('show-n')
                setshowAddForm1('')
                props.onRequest()
                // setRerender(!rerender);
                
                // gateindata()
                onClickHideManualUpload()
                setmanualUploadDetails({
                    wrno: '',
                    container:'',
                    shipping_on_board_date:'',
                    bl_no:'',
                    bl_date:'',
                    bl_file:'',
                    gate_in_date_time:''
                })
                document.getElementById('bl_file').value=''
                document.getElementById('shipping_on_board_date').value=''
                document.getElementById('gate_in_date_time').value = '';
                setshowAddForm('')
                setshowAddForm1('')
                setshowAddForm2('')
                setoverly('show-n')

        }
        else {
            setshow(true)
            setbasicTitle(resp.data.message)
            setbasicType("danger")
            setloadshow('show-n')
            setoverly('show-n')
            // setshowAddForm('')
            props.onRequest()
            // gateindata()

        }
})
       
       
       

        
        // redirectURL.post('/consignments/updateShippingOnBoardDetails', data).then((resp)=>{
        //     if (resp.data.status == 'Success') {
        //         setshow(true)
        //         setbasicTitle("Shipping Onboard updated Successfully")
        //         setbasicType("success")
        //         setloadshow('show-n')
        //         setoverly('show-n')
        //         setshowAddForm1('')
        //         props.onRequest()
        //         setRerender(!rerender);
        //         gateindata()
        //         onClickHideManualUpload()
        //         setmanualUploadDetails({
        //             wrno: '',
        //             container:'',
        //             shipping_on_board_date:'',
        //             bl_no:'',
        //             bl_date:'',
        //             bl_file:'',
        //             gate_in_date_time:''
        //         })
        //         document.getElementById('bl_file').value=''
        //         document.getElementById('shipping_on_board_date').value=''
        //         document.getElementById('gate_in_date_time').value = '';
        //         setshowAddForm('')
        //         setshowAddForm1('')
        //         setshowAddForm2('')
        //         setoverly('show-n')
        //     }
        //     else {
        //         setshow(true)
        //         setbasicTitle(resp.data.message)
        //         setbasicType("danger")
        //         setloadshow('show-n')
        //         setoverly('show-n')
        //         props.onRequest()
        //         gateindata()
        //         // setshowAddForm('')
        //     }
        // })
        //      var gate_in_time_port = document.getElementById("gate_in_date_time").value
        //      var wrno = manualUploadDetails.wrno.value
        //      var container = manualUploadDetails.container.value
        // var rqparams ={
        //     "gate_in_time_port":gate_in_time_port,
        //     "work_order_no":wrno,
        //     "container_no":container
        // }
        // if(wrno!='' && wrno!=undefined && container!='' && container!=undefined && gate_in_time_port!='' && gate_in_time_port!=undefined &&  gate_in_time_port!='__-__-____ __:__'){
        //     redirectURL.post('/consignments/updateGateInPortDetails', rqparams).then((resp) => {
        //         //console.log(resp.data, '176')
        //         if (resp.data.status == 'Success') {
        //             setshow(true)
        //             setbasicTitle("Successfully Updated")
        //             setbasicType("success")
        //             setloadshow('show-n')
        //             setoverly('show-n')
        //             setshowAddForm('')
        //             document.getElementById('gate_in_date_time').value = '';
        //             props.onRequest()
        //             setRerender(!rerender);
        //             gateindata()
        //         }
        //         else {
        //             setshow(true)
        //             setbasicTitle(resp.data.message)
        //             setbasicType("danger")
        //             setloadshow('show-n')
        //             setoverly('show-n')
        //             setshowAddForm('')
        //         }
        //         setmanualUploadDetails({
        //             wrno: '',
        //             container:'',
        //             shipping_on_board_date:'',
        //             bl_no:'',
        //             bl_date:'',
        //             bl_file:'',
        //             gate_in_date_time:''cha
        //         })
        //         document.getElementById('gate_in_time_port').value=''
        //     })
        // }
        // else{
        //     setshow(true)
        //     setbasicTitle('Please Enter All Fields')
        //     setbasicType("danger")
        //     setloadshow('show-n')
        //     setoverly('show-n')
        // }
    }
    const onsubmitdata1 = (e) => {
        e.preventDefault()
             var shipping_on_board_date=manualUploadDetails.shipping_on_board_date
             var wrno = manualUploadDetails.wrno.value
             var container = manualUploadDetails.container.value
        var rqparams ={
            "shipping_on_board_date":shipping_on_board_date,
            "work_order_no":wrno,
            "container_no":container
        }
        if(shipping_on_board_date!='' && shipping_on_board_date!=undefined&&wrno!='' && wrno!=undefined && container!='' && container!=undefined){
            redirectURL.post('/consignments/updateShippingOnBoardDetails', rqparams).then((resp) => {
                //console.log(resp.data, '176')
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Successfully Updated")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setshowAddForm1('')
                    props.onRequest()
                    // setRerender(!rerender);
                    // gateindata()
                    onClickHideManualUpload()
                    setmanualUploadDetails({
                        wrno: '',
                        container:'',
                        shipping_on_board_date:'',
                        bl_no:'',
                        bl_date:'',
                        bl_file:'',
                        gate_in_date_time:''
                    })
                    document.getElementById('bl_file').value=''
                    document.getElementById('shipping_on_board_date').value=''
                    document.getElementById('gate_in_date_time').value = '';
                    setshowAddForm('')
                    setshowAddForm1('')
                    setshowAddForm2('')
                    setoverly('show-n')
                }
                else {
                    setshow(true)
                    setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setshowAddForm('')
                }
            })
        }
        else{
            setshow(true)
            setbasicTitle('Please Enter All Fields')
            setbasicType("danger")
            setloadshow('show-n')
            setoverly('show-n')
        }


    }
    const onsubmitdata2 = (e) => {
        e.preventDefault()
            var bl_no = manualUploadDetails.bl_no
            var bl_date = manualUploadDetails.bl_date
             var wrno = manualUploadDetails.wrno.value
             var container = manualUploadDetails.container.value
             var bl_file = manualUploadDetails.bl_file
            var saveData  = new FormData();
            saveData.append("bl_no",bl_no);
            saveData.append("bl_date",bl_date);
            saveData.append("work_order_no",wrno);
            saveData.append("container_no",container);
            saveData.append("bl_file",bl_file);
            if(wrno!='' && wrno!=undefined && container!='' && container!=undefined){
                redirectURL.post('/consignments/updateBlDetails', saveData).then((resp) => {
                    //console.log(resp.data, '176')
                    if (resp.data.status == 'Success') {
                        setshow(true)
                        setbasicTitle("Successfully Updated BL Details")
                        setbasicType("success")
                        setloadshow('show-n')
                        setoverly('show-n')
                        setshowAddForm('')
                        setshowAddForm1('')
                        setshowAddForm2('')
                        // gateindata()
                        setmanualUploadDetails({
                            wrno: '',
                            container:'',
                            shipping_on_board_date:'',
                            bl_no:'',
                            bl_date:'',
                            bl_file:'',
                            gate_in_date_time:''
                        })
                        document.getElementById('bl_file').value=''
                        props.onRequest()
                        props.onReloadData(true);
                    }
                    else {
                        setshow(true)
                         setbasicTitle(resp.data.message)
                        setbasicType("danger")
                        setloadshow('show-n')
                        setoverly('show-n')
                        setshowAddForm('')
                    }
                    document.getElementById('bl_file').value=''
                })
            }
            else{
                setshow(true)
                setbasicTitle('Please Enter All Fields')
                setbasicType("danger")
                setloadshow('show-n')
                setoverly('show-n')
            }

    }
    const onsubmitdata3 = (e) => {
        e.preventDefault()
             var wrno = manualUploadDetails.wrno.value
             var container = manualUploadDetails.container.value
        var rqparams ={
            "work_order_no":wrno,
            "container_no":container
        }
        if(wrno!='' && wrno!=undefined && container!='' && container!=undefined){
            redirectURL.post('/consignments/updateBufferRequest', rqparams).then((resp) => {
                //console.log(resp.data, '176')
                if (resp.data.status == 'success') {
                    setshow(true)
                    setbasicTitle("Successfully Updated")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setshowAddForm1('')
                    setshowAddForm('')
                    setshowAddForm2('')
                    setshowAddForm3('')
                    setoverly('show-n')
                    props.onRequest()
                    props.onReloadData(true);
                }
                else {
                    setshow(true)
                    setbasicTitle(resp.data.message)
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setshowAddForm('')
                }
                setmanualUploadDetails({
                    wrno: '',
                    container:'',
                    shipping_on_board_date:'',
                    bl_no:'',
                    bl_date:'',
                    bl_file:'',
                    gate_in_date_time:''
                })
                document.getElementById('gate_in_time_port').value=''
            })
        }
        else{
            setshow(true)
                    setbasicTitle('Please Enter All Manditory Fields')
                    setbasicType("danger")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setshowAddForm('')
        }


    }
    var changeOrderFileHandlerFcrBulk = async (e) => {
        setfile(e.target.files[0])
        var csvdata = await BulkForceFileHandler(e, [
            { name: 'Work Order No', inputName: 'work_order_no', required: true },
            { name: 'Tentative shipping line', inputName: 'tentative_shipping_line', required: true },
            { name: 'Tentative vessel ETD', inputName: 'tentative_vessel_etd', required: true },
            { name: 'Tentative Gate Open Date', inputName: 'tentative_gate_open_date', required: true },
            { name: 'Tentative cut off date', inputName: 'tentative_cut_off_date', required: true },
            { name: 'Remarks(by CHA)', inputName: 'remarks', required: true },

        ])
        //console.log(csvdata, '1234')
        setcsvcontent(csvdata)
    }
    const onSelectWON = (e , selector) => {
        if(selector =="wrno"){
            setmanualUploadDetails(prev => ({
                ...prev,
                wrno: { label: e.value, value: e.value }
            }))
            
            var containerList =[] 
            if(containerLegsData.length > 0){
                var containerdetails = containerLegsData
                containerLegsData.map(f => {
                    if(f.work_order_no == e.value){
                        containerList.push({label : f.container_no , value :f.container_no})
                    }else{

                    }
                })
                var containersListforgatein=[]
                var containersforgatein = containerdetails.filter(f=>f.shipping_onboard_enable!=1&&e.value==f.work_order_no)
                //console.log(containersforgatein,'containersListforgatein123')

                containersforgatein.map((f)=>{
                    if(f.shipping_onboard_enable!=1){
                        containersListforgatein.push({label : f.container_no , value :f.container_no})
                    }
                })
                setcontainersforgatein(containersListforgatein)
                //console.log(containersListforgatein,'containersListforgatein123')
                var containersListforshipping=[]
                var containersforshipping= containerdetails.filter(f=>f.bl_details_enable!=1&&e.value==f.work_order_no)
                containersforshipping.map((f)=>{
                    if(f.bl_details_enable!=1){
                        containersListforshipping.push({label : f.container_no , value :f.container_no})
                    }
                })
                setcontainersforshipping(containersListforshipping)
                var containersListforbldetails=[]
                var containersforbldetails= containerdetails.filter(f=>f.bl_no==undefined&&e.value==f.work_order_no)
                containersforbldetails.map((f)=>{
                    if(f.bl_no==undefined){
                        containersListforbldetails.push({label : f.container_no , value :f.container_no})
                    }
                })
                setcontainersforbl(containersListforbldetails)
            }
            //console.log(containerList,'containerList')
            var containersforgatein = 
            setContainerNoList(containerList)
            
        }
        if(selector == 'container'){
            setmanualUploadDetails(prev => ({
                ...prev,
                container: { label: e.value, value: e.value }
            }))
        }
        
    }
    const onSelectWON1 = (e , selector) => {
        //console.log(e , selector , "onSelectWON")
        if(selector =="wrno"){
            setmanualUploadDetails(prev => ({
                ...prev,
                wrno: { label: e.value, value: e.value }
            }))
            var containerList=[]
            var containerdetails = containerLegsData
            var containersforgatein = containerdetails.filter(f=>f.shipping_onboard_enable!=1&&e.value==f.work_order_no)
            //console.log(containersforgatein,'containersListforgatein123')

            containersforgatein.map((f)=>{
                if(f.shipping_onboard_enable!=1){
                    containerList.push({label : f.container_no , value :f.container_no})
                }
            })
            setContainerNoList3(containerList)
            //console.log(containerList,'containersListforgatein123')
            
            // var containerList =[] 
            // if(containerLegsData.length > 0){
            //     //console.log(containerLegsData,'containerLegsDatacontainerLegsData')
            //     containerLegsData.map(f => {
            //         if(f.work_order_no == e.value){
            //             containerList.push({label : f.container_no , value :f.container_no})
            //         }else{

            //         }
            //     })
    
            // }
            // //console.log(containerList,'containerList1')
            //  setContainerNoList3(containerList)
            
        }
        // if(selector == 'container'){
        //     setmanualUploadDetails(prev => ({
        //         ...prev,
        //         container: { label: e.value, value: e.value }
        //     }))
        // }
        
    }
    const onSubmitOrderBulkUpload = (event) => {
        event.preventDefault();
        setloadshow('show-m')
        if (file != '') {
            var rparams = {
                data: csvcontent,
            }
            redirectURL.post('/consignments/uploadBulkuploadforCHAdata', rparams).then((resp) => {
                if (resp.data.status == 'Success') {
                    setshow(true)
                    setbasicTitle("Successfully Uploaded")
                    setbasicType("success")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                }
                else {
                    setshow(true)
                    // setbasicTitle("Successfully Uploaded")
                    setbasicType("Failure")
                    setloadshow('show-n')
                    setoverly('show-n')
                    setsliderForBulkupload('')
                    document.getElementById("uploadfile").value = null
                }
                //console.log(resp.data)
            })
        }
    }
    const onClickHide = () => {
        setsliderForBulkupload('')
        document.getElementById("uploadfile").value = null
        setoverly('show-n')
    }
    const selectedrow=(e)=>{
        //console.log(e,'eee')
        onsubmitdata(e)
      }
    const onClickHideManualUpload = () => {
        setshowAddForm('')
        setshowAddForm1('')
        setshowAddForm2('')
        setoverly('show-n')
        setmanualUploadDetails({
            wrno: '',
            container:'',
            shipping_on_board_date:'',
            bl_no:'',
            bl_date:'',
            bl_file:'',
            gate_in_date_time:''
        })
        document.getElementById('bl_file').value=''
        document.getElementById('gate_in_date_time').value = '';
        //console.log('1268')


    }
    const onClickHideManualUploadforbuffer = () => {
        setshowAddForm('')
        setshowAddForm1('')
        setshowAddForm2('')
        setshowAddForm3('')
        setoverly('show-n')
        setmanualUploadDetails({
            wrno: '',
            container:'',
            shipping_on_board_date:'',
            bl_no:'',
            bl_date:'',
            bl_file:'',
            gate_in_date_time:''
        })
        document.getElementById('gate_in_time_port').value=''

    }
    const onClickHideAll = () => {
        setloadshow('show-n')
    }
    var closeAlert = () => {
        setshow(false)
        setloadshow('show-n')
    }
    var onclickbulkupload = () => {
        setsliderForBulkupload("slider-translate")
        setoverly('show-m')
    }
    const onClickCounters = (props) => {
        if (props == 'vesselAvailability') {
            setcheck(1)
            setIsClicked({ vessel_avail: true })
        }
        if (props == 'uploadDO') {
            setcheck(2)
            setIsClicked({ uploadDo: true })
        }
        else {
            setIsClicked(false)
        }
    }
    const onchangeFileHandler =(e)=>{
    var check = e.target.files[0].name
    var file_check = check.split(".")
    var next_check = file_check[file_check.length -1]
    //console.log(e.target.files[0],"adfdfadfa")
        if(next_check == "pdf" || next_check == "png" || next_check == "jpeg"){
            setmanualUploadDetails(prev => ({ ...prev, bl_file: e.target.files[0] }))
        }else{
            setbasicTitle("Please Upload pdf or png File")
            setbasicType("warning")
            setshow(true)
            $("#bl_file").val("")
        }
    }
    var sDate = $("#wo_order_date").val()
    var acknowledgebtn= {
        headerName: "",
        field: "",
        width: 120,
        filter: true, resizable: true,
        pinned: 'left',
        cellRendererFramework: AcknowledgeBtnComponent
    }
    var upload_details =  {
        headerName: "Update",
        field: "update_shipping_onboard",
        width: 80,
        pinned: 'left',
        cellRenderer: 'UpdateShippingOnBoardBtn'
    } 
    var newCount = tabforgatein.onboard_new
    var plannedCount = tabforgatein.onboard_planned
    var columnwithdefs2 = [
        {
            headerName: 'Work Order No',
            field: "work_order_no",
            resizable: true,
            width: 150,
            filter: true
        },
        {
            headerName: 'Truck No',
            field: "truck_no",
            resizable: true,
            width: 150,
            filter: true
        },
        {
            headerName: 'Container No',
            field: 'container_no',
            resizable: true,
            width: 150,
            filter: true,
        },
        {
            headerName: 'Shipping On Board Date',
            field: "shipping_on_board_date",
            resizable: true,
            width: 240,
            filter: true,
            editable: true,
            cellEditor: DateEditor,
            valueGetter: function (params) {
                try {
                    if (params.data.shipping_on_board_date != "" && params.data.shipping_on_board_date != undefined) {
                        return getHyphenDDMMYYYY(params.data.shipping_on_board_date);
                    } else {
                        return "";
                    }
                } catch (e) {
                }
            },
            comparator: dateComparator1,
        },
    ]
    var columns1 = [...summaryViewcols] 
    if (classForTab.new ==1){
        const pendencyIndex = columns1.findIndex(col=> col.field==="pendency_shipping_on_board_date");
        if(pendencyIndex!==-1){
            columns1[pendencyIndex].hide=false;
        }
    }
    if (classForTab.planned ==1){
        const pendencyIndex = columns1.findIndex(col=> col.field==="pendency_shipping_on_board_date");
        if(pendencyIndex!==-1){
            columns1[pendencyIndex].hide=true;
        }
    }

    if(classForTab.modified==1){
        columns1.push(acknowledgebtn)
    }
    if(classForTab.new==1){
        columns1.push(upload_details)
    }
    const columnwithDefs = columns1
    //console.log(columnwithDefs,'columnwithDefscolumnwithDefs')
    return (
        <>
            <SweetAlert
                show={show}
                type={basicType}
                title={basicTitle}
                onConfirm={closeAlert}
            >
            </SweetAlert>

            {/* <div className="row">
                {props.check == 4 ?

                    <div className="form-group col-xl-2 col-lg-2 mt-24p">
                        <Select
                            closeMenuOnSelect={true}
                            name="filterModeType"
                            className="border-radius-0"
                            styles={{
                                control: (provided, state) => ({
                                            ...provided,
                                            fontSize: "0.8rem",
                                            color: "#6e707e",
                                            borderRadius:"11px",
                                            height:"48px",
                                            width:"260px",
                                            // padding:"22px",
                                            textAlign:"center"
                                        }),
                            }}
                            onChange={handleFilterOptions}
                            options={typeofselection}
                            value={selectedval.dropdownval}
                            required
                        />
                    </div> : ''}
                {checkinput == 1 ?
                    <div
                        className="d-flex mt-24p  mr-10p mt-d"
                        style={{ position: "relative" }}
                    >
                        <input
                            style={{
                                border: "1px solid #cccccc",
                                borderRadius: "11px",
                                height: "48px",
                                outline: "none",
                                paddingLeft: "6px",
                            }}
                            onChange={handleInputfilter}
                            value={selectedval.inputval}
                        />
                        <img
                            src={SearchIcon}
                            className="search_icon"
                            onClick={filterData}
                        />
                    </div>
                    : ''}
                {checkForDateFilter == 1 ?
                    <div className="mt-24p mt-d">  <Datepicker id='production_date' onDatesSelected={handleDatesSelected} /> <img
                        src={SearchIcon}
                        className="search_icon_for_dates"
                        onClick={filterDatafordates}
                    /></div>

                    : ""}
                    {checkForDateFilter == 2 ?
                    <div className="mt-24p mt-d">  <Datepicker id='production_date' onDatesSelected={handleDatesSelected1} /> <img
                        src={SearchIcon}
                        className="search_icon_for_dates"
                        onClick={filterDatafordates1}
                    /></div>

                    : ""}
            </div> */}

            {props.check == 4 ?

                <div className="m-10p" style={{ margin: '10px', marginTop: '0px' }}>
                    <div className="d-flex flex-row" style={{position:"relative"}}>
                    <button
                        className="d-flex flex-row justify-content-center align-items-center px-3"
                        style={{
                            background: classForTab.new == 1 ? '#0664AE' : '#FFFFFF',
                            // color: state.isTileView ? "": "#0664AE",
                            borderTopLeftRadius: "11px",
                            borderBottomLeftRadius: "11px",
                            border: "1px solid #0664AE",
                            height: "38px",
                            padding: '22px'
                        }}
                        onClick={() => onclickTab('New')}
                    >
                        <div
                            style={{
                                color: classForTab.new == 1 ? "white" : '#0664AE',
                                fontSize: "12px",
                                fontWeight: 700,
                                marginLeft: "10px",
                                lineHeight: "12px",
                            }}
                        >
                            New - <span>{(newCount != '' && newCount != undefined) ? newCount.length : 0}</span>
                        </div>
                    </button>

                    <button
                        className="d-flex flex-row justify-content-center align-items-center px-3"
                        style={{
                            background: classForTab.planned == 1 ? '#0664AE' : "#FFFFFF",
                            border: "1px solid #0664AE",
                            borderTopRightRadius: "11px",
                            borderBottomRightRadius: "11px",
                            height: "38px",
                            padding: '22px'
                        }}
                        onClick={() => onclickTab('Planned')}
                    >
                        <div
                            style={{
                                color: classForTab.planned == 1 ? "white" : '#0664AE',
                                fontSize: "12px",
                                fontWeight: 700,
                                marginLeft: "10px",
                                lineHeight: "12px",
                            }}
                        >
                            Planned - <span>{(plannedCount != '' && plannedCount != undefined) ? plannedCount.length : 0}</span>
                        </div>
                    </button>
                    {/* <button
                        className="d-flex flex-row justify-content-center align-items-center px-3"
                        style={{
                            background: classForTab.modified == 1 ? '#0664AE' : "#FFFFFF",
                            borderTopRightRadius: "11px",
                            borderBottomRightRadius: "11px",
                            border: "1px solid #0664AE",
                            height: "38px",
                            padding: '22px'
                        }}
                        onClick={() => onclickTab('Modified')}
                    >
                        <div
                            style={{
                                color: classForTab.modified == 1 ? "white" : '#0664AE',
                                fontSize: "12px",
                                fontWeight: 700,
                                marginLeft: "10px",
                                lineHeight: "12px",
                            }}
                        >
                            Modified - <span>{(modifiedCount != '' && modifiedCount != undefined) ? modifiedCount.length : 0}</span>
                        </div>
                    </button> */}
                                {classForTab.new===1?
                         <div style={{position:"absolute", right:"3px",top:"-25px" }}>
                            <button
                                        type="button"
                                        title="Save Grid Layout"
                                        name="savegrid"
                                        className="btn btn-danger action_btn"
                                        style={{height: "32px", marginTop: "30px", paddingBottom:"30px" }}

                                        onClick={onClickSaveGridState}>
                                        <i className="f12 icofont icofont-save"></i>
                                        </button>
                                </div>
                            :''}
                            {/* <p style={{color:'red'}}>Note: Modification Pending At Previous Action</p> */}
                        {/* </div>  */}
                {/* <p style={{color:'red'}}>Note: Modification Pending At Previous Action</p> */}
                    </div>
                    <div id="idgrid" style={{ width: "100%", height: "478px" }} className="ag-theme-balham">
                        <AgGridReact
                            columnDefs={columnwithDefs}
                            defaultColDef={{
                                sortable: true,
                                filter: true,
                                editable: false,
                                resizable: true,
                                menuTabs: ['filterMenuTab']
                            }}
                            // paddingTop={10}
                            // rowHeight={70}
                            // headerHeight = {70}
                            rowData={rowData}
                            suppressRowClickSelection={true}
                            detailCellRendererParams={state.detailCellRendererParams}
                            frameworkComponents={{ConsignmentTrucksLegs:ConsignmentTrucksLegs,CountryFlagComponent:CountryFlagComponent,AcknowledgeBtnComponent:AcknowledgeBtnComponent,UpdateShippingOnBoardBtn:UpdateShippingOnBoardBtn}}
                            enableCellChangeFlash={true}
                            suppressCellFlash={true}
                            enableRangeSelection={true}
                            paginationPageSize={10}
                            rowSelection={'multiple'}
                            masterDetail={true}
                            pagination={true}
                            sideBar={{
                                toolPanels: [
                                    {
                                        id: "columns",
                                        labelDefault: "Columns",
                                        labelKey: "columns",
                                        iconKey: "columns",
                                        toolPanel: "agColumnsToolPanel"
                                    },
                                    {
                                        id: "filters",
                                        labelDefault: "Filters",
                                        labelKey: "filters",
                                        iconKey: "filter",
                                        toolPanel: "agFiltersToolPanel"
                                    }
                                ]
                            }}
                            statusBar={{
                                statusPanels: [
                                    // {
                                    //     statusPanel: "agTotalAndFilteredRowCountComponent",
                                    //     align: "left"
                                    // },
                                    // {
                                    //     statusPanel: "agTotalRowCountComponent",
                                    //     align: "center"
                                    // },
                                    { statusPanel: "agFilteredRowCountComponent" },
                                    { statusPanel: "agSelectedRowCountComponent" },
                                    { statusPanel: "agAggregationComponent" }
                                ]
                            }}
                            gridOptions={{
                                icons: {
                                    // You can customize other icons as well
                                    next: `<img src="${customNextIcon}" alt="Next" />`,
                                    previous: `<img src="${customPreviousIcon}" alt="Previous" />`,
                                    first: `<img src="${customFirstIcon}" alt="First" />`,
                                    last: `<img src="${customLastIcon}" alt="Last" />`,
                                  },
                            }}
                            ref={gridApi}
                            onGridReady={onGridReady}
                            onGridState={onGridState}
                            getRowStyle={getRowClass}
                            context={{onClickShowShipmentLegs,onClickAcknowledgeBtn,onClickshippingonboard}}
                        />

                    </div>
                </div> : ''}

            <div className={"slide-r " + sliderForBulkupload} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Bulk Upload</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHide} >X</span>
                </div>
                <div className="slide-r-body" style={{ position: "relative" }}>
                    <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >

                        <div className="theme-form col-xl-12 col-lg-12">

                            <form action="" onSubmit={onSubmitOrderBulkUpload}>
                                <div className="col-xl-12 col-lg-12 row form-group">
                                    <label className="col-xl-12 col-lg-12">Upload File *</label>
                                    <input
                                        type="file"
                                        name="uploadfile"
                                        id="uploadfile"
                                        className="form-control"
                                        onChange={changeOrderFileHandlerFcrBulk}
                                        required
                                    />
                                </div>

                                <div className="col-xl-12 col-lg-12 form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                </div>
                            </form>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/cha_based_upload_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                            <p style={{ color: 'red' }}>Note: </p>
                            <p>Tentative Shipment Line & Remarks should be in 'String' format. <br />
                                Tentative Vessel ETD & Tentative Gate Open Date & Tentative cut off date should be in 'DD-MM-YYYY' format</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className={"sliderBlock2 " + showAddForm1} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter Shipping Onboard Date</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                       <ShippingonboardGrid columnwithdefs2={columnwithdefs2} selected_row={selected_row} rowData1={rowData1} onselectedrows={selectedrow}/>
                    </div>
                </div>
            </div>
            <div className={"sliderBlock2 " + showAddForm2} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter Bill Of Lading</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUpload} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata2}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Work Order No <span className="err-txt">*</span> </label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.wrno}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON(e , "wrno")}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={work_order3}
                                        />
                                    </div>
                                    {manualUploadDetails.wrno!="" ?
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Container No <span className="err-txt">*</span> </label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.container}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON(e , "container")}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={containersforbl}
                                        />
                                    </div>
                                    :""}
                                    {manualUploadDetails.container!=""?
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>BL No <span className="err-txt">*</span></label>
                                        <input
                                            type="text"
                                            name="bl_no"
                                            id="bl_no"
                                            value={manualUploadDetails.bl_no}
                                            className="form-control"
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, bl_no: e.target.value }))}
                                            required
                                        />
                                    </div>:''}
                                    {manualUploadDetails.bl_no!=""?
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>BL Date <span className="err-txt">*</span></label>
                                        <Datetime
                                            key={manualUploadDetails.bl_date}
                                            placeholder="Date"
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            name="bl_date"
                                            value={manualUploadDetails.bl_date}
                                            dateFormat="DD-MM-YYYY"
                                            id='bl_date'
                                            timeFormat={false}
                                            onChange={(e) => setmanualUploadDetails(prev => ({ ...prev, bl_date: changeHandleDate(e) }))}
                                            required />
                                    </div>:''}
                                    {manualUploadDetails.bl_date!=""?
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>BL File <span className="err-txt">*</span></label>
                                        <input type="file" name="bl_file" id="bl_file" className="form-control requestFields" onChange={(e)=>onchangeFileHandler(e)} />
                                    </div>:''}
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
            {stateForMap.sliderRouteTranslate != '' ?
				<div className={"sliderBlock2 " + stateForMap.sliderRouteTranslate} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Truck No:{(stateForMap.container_no)}
                            <span className="float-right closebtn" style={{  marginRight: "100px" }} onClick={onCloseRouteDiv} >X</span>
						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                            {/* {//console.log("this.state.mapinfo ", this.state.mapinfo)} */}
                            <Mapcard2
                                 mapinfo={stateForMap.mapinfo}
                                 mapHeight={"100vh"}
                                 mapFor={"intransit"}
                                 mapData={stateForMap.mapdata}
                                geoLocShipmentsMap={state.geoLocShipmentsMap}

                            />
						</div>
					</div>

				</div>
				: ""
			}
            <div className={"sliderBlock2 " + showAddForm3} style={{ overflow: "auto" }}>
                <div className="slide-r-title slider_title_style">
                    <h6>Enter Details</h6>
                    <span className="float-right closebtn" style={{ marginTop: '-30px', marginRight: "25px" }} onClick={onClickHideManualUploadforbuffer} >X</span>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <form onSubmit={onsubmitdata3}>
                            <div className="slide-body">
                                <div className="row p-20p">
                                <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Work Order No <span className="err-txt">*</span> </label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.wrno}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON1(e , "wrno")}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={workOrder}
                                        />
                                    </div>
                                    {/* <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Container No <span className="err-txt">*</span> </label>
                                        <Select
                                            // placeholder="Change Activity Status"
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.container}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON(e , "container")}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={containersforgatein}
                                        />
                                    </div> */}
                                    {manualUploadDetails.wrno!="" ?
                                    <div className="form-group col-xl-12 col-lg-12">
                                        <label className='mb-5p'>Container No <span className="err-txt">*</span> </label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            value={manualUploadDetails.container}
                                            // multi={true}
                                            // className={"border-radius-0"} 
                                            onChange={(e) => onSelectWON(e , "container")}
                                            style={{ borderRadius: "0px" }}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    fontSize: "0.8rem",
                                                    color: "#6e707e",
                                                }),
                                            }}
                                            options={containernoList3}
                                        />
                                    </div>
                                    :""}
                                    <div className="form-group col-sm-12 mb-20p text-center">
                                        <button type="submit" className="btn btn-success">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>


            </div>
            <div className={"dataLoadpage " + (loadshow)}>
            </div>
            <div className={"dataLoadpageimg " + (loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part " + (overly)} onClick={onClickHideAll}></div>

        </>
    )
}
function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
    var diffMs = n;
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
    var timeinfo = new window.google.maps.InfoWindow({
        content: "Time at this location: " + timedet
    });
    marker.addListener('click', function () {

        if (currentinfowindow != null) {
            currentinfowindow.close();
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
        else {
            currentinfowindow = timeinfo;
            timeinfo.open(marker.get('map'), marker);
        }
    });
}
function secondsToString(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function secondsToDays(seconds) {
    var numdays = Math.floor(seconds / 86400);
    var numhours = Math.floor((seconds % 86400) / 3600);
    var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
    var numseconds = ((seconds % 86400) % 3600) % 60;
    return numdays;
}
function CheckForHtmlTag() {
    var rs = document.getElementById("invalid_reason");
    var reg = /<(.|\n)*?>/g;
    if (reg.test(rs.value)) {
        var ErrorText = "Oops! HTML or Script is not allowed.";
        rs.value = ''
        //alert();
        return 1;
    }
}
function dateComparator(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDateTimeScript() {
    $('.datetimepicker_mask').datetimepicker({
        mask: '39-19-9999',
        format: 'd-m-Y',
        onShow: false
    });
    $('.datetimepicker_date').datetimepicker({
        format: 'd-m-Y',
        timepicker: false,
        onShow: false
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function sortByCreatedDate(arr, descending = true) {
    if (arr != '' && arr != undefined) {
      if (arr.length > 0) {
        const comparison = (a, b) => {
          const dateA = new Date(a.modified_date);
          const dateB = new Date(b.modified_date);
          if (descending) {
            return dateB - dateA; // Ascending order
          } else {
            return dateA - dateB; // Descending order
          }
        };
          arr.sort(comparison);
        return arr; 
      }
    } else {
      return arr; 
    }
  }
function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortAscOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function GetSortASCOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}
function distinctArrayBy(arr, propName) {
    var result = arr.reduce(function (arr1, e1) {
        var matches = arr1.filter(function (e2) {
            return e1[propName] == e2[propName];
        })
        if (matches.length == 0)
            arr1.push(e1)
        return arr1;
    }, []);

    return result;
}
function dateComparator1(date1, date2) {
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        var date_1 = getMonthNumber(date1.split("-")[1]);
        date1 = date1.replace(date1.split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split("-")[1]);
        date2 = date2.replace(date2.split("-")[1], date_2);
        var date1Number = monthToComparableNumber1(date1);
        var date2Number = monthToComparableNumber1(date2);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        //console.log(date1Number, date2Number, "comparator")
        return date1Number - date2Number;
    }
}
function monthToComparableNumber1(date) {
    if (date === undefined || date === null || date.length !== 10) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}
function loadDateTimeScript2() {
    $('.datetimepicker_mask').datetimepicker({
        format: 'd-m-Y'
    });
    $('.device_filter_data').datetimepicker({
        format: 'Y-m-d',
        timepicker: true
    });
    var index = window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async = true
    script.defer = true
    index.parentNode.insertBefore(script, index)
}
function getDatePicker() {
    function Datepicker() { }
    Datepicker.prototype.init = function (params) {
        this.eInput = document.createElement("input");
        this.eInput.value = params.value;
        //console.log(this.eInput)
        window.$(".datepicker").datepicker({ dateFormat: "dd-mm-yy" });
    };
    Datepicker.prototype.getGui = function () {
        return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.eInput.focus();
        this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
        return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () { };
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
}
function DateEditor() { }
DateEditor.prototype.init = function (params) {
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;
    $(this.eInput).datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
        showTime: false,
    });
};
DateEditor.prototype.getGui = function () {
    return this.eInput;
};
DateEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
    this.eInput.select();
};
DateEditor.prototype.getValue = function () {
    return this.eInput.value;
};
DateEditor.prototype.destroy = function () {
};
DateEditor.prototype.isPopup = function () {
    return false;
};
function removeDuplicates(arr, prop) {
    const seen = new Set();
    return arr.filter(obj => {
        const propValue = obj[prop];
        const propString = typeof propValue === 'object' ? JSON.stringify(propValue) : propValue;
        if (seen.has(propString)) {
            return false;
        }
        seen.add(propString);
        return true;
    });
}
export default ShippingOnBoardComponent